import { Card, CardActions, CardContent, CardHeader, CardProps, Grid } from '@mui/material';
import { Scrollbar } from 'src/components/scrollbar/Scrollbar';
import { UiLink } from 'src/components/ui/UiLink';
import { FCC } from 'src/types/FCC';
import { ReactElement } from 'react';

interface Props {
  link: {
    to: string;
    text: string;
  };
  title: string;
  UnderTitle?: ReactElement;
}

export const LastItemsContainer: FCC<CardProps & Props> = ({
  children,
  link,
  title,
  UnderTitle,
  ...others
}) => {
  return (
    <Card {...others} sx={{ boxShadow: 'none', color: (theme) => theme.palette.text.primary }}>
      <UiLink to={link.to} style={{ textDecoration: 'none', color: 'inherit' }}>
        <CardHeader title={title} sx={{ pl: 0 }} />
      </UiLink>

      {UnderTitle}

      <CardContent sx={{ paddingBottom: 1, px: 0 }}>
        <Scrollbar>
          <Grid container spacing={2} flexWrap="nowrap" pb={2}>
            {children}
          </Grid>
        </Scrollbar>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', pr: 0 }}>
        <UiLink to={link.to} color="inherit">
          {link.text}
        </UiLink>
      </CardActions>
    </Card>
  );
};
