import { AccordionDetails, Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useParams } from 'react-router-dom';
import { useFetch } from 'src/hooks/useFetch';
import { ApiAddressInfoResp, ApiContractInfo, ApiContractMethod } from 'src/types/MultidataApi';
import { ProxyContractMessage } from './ProxyContractMessage';
import { UiAccordion, UiAccordionSummary } from '../ui/UiAccordion';
import { WalletConnect, WalletData } from '../WalletConnect';
import { WriteForm } from './contractForm/WriteForm';

export const WriteContract = ({
  addressInfo,
  contractInfo,
  getContractWriteMethods,
}: {
  addressInfo?: ApiAddressInfoResp;
  contractInfo: ApiContractInfo;
  getContractWriteMethods: (address: string) => Promise<ApiContractMethod[]>;
}) => {
  const { address } = useParams();
  const [walletNetworkId, setWalletNetworkId] = useState<number | null>(null);
  const [signer, setSigner] = useState<ethers.Signer | null>(null);
  const [contract, setContract] = useState<ethers.Contract | null>(null);

  const {
    fetchData,
    isLoading,
    txStatus,
    data: writeMethods,
  } = useFetch<ApiContractMethod[]>('failed to get write methods: ');

  useEffect(() => {
    if (!address) return;
    fetchData(getContractWriteMethods(address));
  }, [address]);

  useEffect(() => {
    if (!signer || !address) return;
    const contract = new ethers.Contract(address, contractInfo.abi, signer);
    setContract(contract);
  }, [signer, address, contractInfo.abi]);

  const handleWalletConnect = useCallback((walletData: WalletData) => {
    const { signer } = walletData;
    setSigner(signer);
  }, []);

  const handleChainChange = useCallback((chainId: number | null) => {
    setWalletNetworkId(chainId);
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (txStatus.error) return <p>{txStatus.error}</p>;
  if (!writeMethods) return null;

  return (
    <Box>
      <Box mb="2rem">
        <WalletConnect onConnect={handleWalletConnect} onChainChange={handleChainChange} />
      </Box>
      {addressInfo?.implementation_address && (
        <ProxyContractMessage
          address={addressInfo.implementation_address}
          name={addressInfo.implementation_name}
        />
      )}
      {writeMethods.map((method, index) => {
        if (method.type === 'function') {
          return (
            <Box key={index} mb="1rem">
              <UiAccordion>
                <UiAccordionSummary
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography>
                    {index + 1}. {method.name}
                  </Typography>
                </UiAccordionSummary>
                <AccordionDetails>
                  {method.error && <Typography color="error">ERROR: {method.error}</Typography>}
                  {method.inputs.length > 0 && (
                    <WriteForm
                      method={method}
                      contract={contract}
                      disabledSubmit={!signer}
                      walletNetworkId={walletNetworkId}
                    />
                  )}
                  {!method.error && !method.inputs.length && (
                    <Typography>No data to display</Typography>
                  )}
                </AccordionDetails>
              </UiAccordion>
            </Box>
          );
        }
      })}
    </Box>
  );
};
