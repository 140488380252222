import {
  InputAdornment,
  Pagination,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Iconify } from 'src/components/Iconify';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiLink } from 'src/components/ui/UiLink';
import { useDebounce } from 'src/hooks/useDebounce';
import { useFetch } from 'src/hooks/useFetch';
import { formatNumber } from 'src/utils/formatNumber';
import { jsonFetch } from 'src/utils/jsonFetch';
import { formatTimeStampUtc } from 'src/utils/time';

const TABLE_HEAD_LABELS = [
  { id: 'name', label: 'Name', width: '12rem' },
  { id: 'symbol', label: 'Symbol', width: '12rem' },
  { id: 'price', label: 'Price', width: '12rem' },
  { id: 'date', label: 'Last updated', width: '12rem' },
  { id: 'address', label: 'Address' },
];

type AggregatorAsset = {
  address?: string;
  id: string;
  name: string;
  symbol: string;
  market_data: {
    current_price?: { usd: number };
    last_updated: string;
  };
};

type AggregatorResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: AggregatorAsset[];
};

const elementsPerPage = 100;
const startPage = 1;

export const AggregatorAssets = memo(() => {
  const [assets, setAssets] = useState<AggregatorResponse>();
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(startPage);
  const isInitRef = useRef(true);

  const [query, setQuery] = useSearchParams();

  const { fetchData, isLoading } = useFetch<AggregatorResponse>(
    'failed to get aggregator assets: ',
  );

  const queryParams = useMemo(() => {
    const queryPageNumber = query.get('page_number');
    const page_number = queryPageNumber ? parseInt(queryPageNumber) : startPage;
    return { page_number };
  }, [query]);

  useEffect(() => {
    const { page_number } = queryParams;

    const getData = async () => {
      const data = await fetchData(
        jsonFetch(
          `https://aggregator-api.chunk.limo/v1/assets/?search=${searchValue}&page=${page_number}&page_size=${elementsPerPage}`,
        ),
      );

      setAssets(data as AggregatorResponse);
    };

    getData();
  }, [queryParams, searchValue]);

  useEffect(() => {
    const { page_number } = queryParams;

    setPage(page_number);
  }, [queryParams]);

  function handlePageChange(page: number) {
    if (page) {
      query.set('page_number', String(page));
    } else {
      query.delete('page_number');
    }
    setQuery(query);
  }

  const handleSearchChange = useCallback((value: string) => {
    if (!isInitRef.current) {
      query.delete('page_number');
      setQuery(query);
    }
    setSearchValue(value);
    isInitRef.current = false;
  }, []);

  const totalPages = useMemo(() => {
    if (!assets) return null;
    return Math.ceil(assets.count / elementsPerPage);
  }, [assets]);

  return (
    <>
      <Stack direction={'row'} flexWrap={'wrap'} gap="15px">
        <SearchInput onChange={handleSearchChange} />

        <Stack
          sx={{ marginLeft: 'auto' }}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-end', md: 'center' }}
        >
          <Pagination
            count={totalPages || 0}
            page={page}
            onChange={(_, page) => handlePageChange(page)}
            color="standard"
            disabled={isLoading}
          />
          <Typography sx={{ fontSize: '0.875rem' }}>
            Assets found:{' '}
            <Typography component={'span'} sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
              {assets?.count}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <TableContainer
        sx={{
          '& th:first-of-type, td:first-of-type': {
            paddingLeft: 3,
          },
        }}
      >
        <Table stickyHeader sx={{ overflowX: 'scroll' }}>
          <TableHeadCustom headLabel={TABLE_HEAD_LABELS} />

          <TableBody>
            {isLoading ? (
              <PricesTableSkeleton />
            ) : assets && assets.results.length === 0 ? (
              <Typography>Not found assets</Typography>
            ) : (
              assets &&
              assets.results.map((asset) => (
                <TableRow key={asset.id}>
                  <TableCell
                    sx={{
                      verticalAlign: 'top',
                    }}
                  >
                    {asset.address ? (
                      <UiLink
                        to={`https://etherscan.io/token/${asset.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography
                          variant={'subtitle1'}
                          whiteSpace="nowrap"
                          sx={{
                            width: '250px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {asset.name}
                        </Typography>
                      </UiLink>
                    ) : (
                      <Typography
                        variant={'subtitle1'}
                        whiteSpace="nowrap"
                        sx={{
                          width: '250px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {asset.name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Typography component="span" variant={'subtitle1'} whiteSpace="nowrap">
                      {asset.symbol}
                    </Typography>
                  </TableCell>
                  {asset.market_data && asset.market_data.current_price && (
                    <TableCell sx={{ verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                      ${formatNumber.getDisplay(asset.market_data.current_price.usd, 0, 4)} USD
                    </TableCell>
                  )}
                  {asset.market_data && asset.market_data.last_updated && (
                    <TableCell sx={{ verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                      {asset.market_data.last_updated
                        ? formatTimeStampUtc(asset.market_data.last_updated)
                        : ''}
                    </TableCell>
                  )}
                  <TableCell sx={{ verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                    {asset.address && (
                      <UiLink
                        to={`https://etherscan.io/token/${asset.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {asset.address}
                      </UiLink>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages !== 0 && (
        <Stack
          sx={{ marginLeft: 'auto', width: 'max-content', marginTop: '12px' }}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-end', md: 'center' }}
        >
          <Pagination
            count={totalPages || 0}
            page={page}
            onChange={(_, page) => handlePageChange(page)}
            color="standard"
            disabled={isLoading}
          />
          <Typography sx={{ fontSize: '0.875rem' }}>
            Assets found:{' '}
            <Typography component={'span'} sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
              {assets?.count}
            </Typography>
          </Typography>
        </Stack>
      )}
    </>
  );
});

AggregatorAssets.displayName = 'AggregatorAssets';

const SearchInput = memo(({ onChange }: { onChange: (value: string) => void }) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, 500);

  const handleSearchChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(evt.target.value);
  }, []);

  useEffect(() => {
    onChange(debouncedSearch);
  }, [onChange, debouncedSearch]);

  return (
    <TextField
      variant="standard"
      autoFocus
      placeholder="Search by Name"
      value={searchValue}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      sx={{ width: { sm: '18rem', xs: '100%' }, pr: 3, alignSelf: 'end' }}
    />
  );
});

SearchInput.displayName = 'SearchInput';

const PricesTableSkeleton = () => {
  const settings = [
    ['220px', '60px', undefined, '188px', '240px'],
    ['220px', '60px', undefined, '120px', '240px'],
    [undefined, undefined, undefined, undefined, '240px'],
    ['120px', '120px', undefined, '120px', '240px'],
    ['120px', '60px', undefined, '120px', '240px'],
  ];

  const renderSkeleton = (rowSettings: (string | undefined)[], index: number) => (
    <TableRow key={index}>
      {rowSettings.map((cellSize, index) => (
        <TableCell key={index} sx={{ verticalAlign: 'top' }}>
          {cellSize ? <Skeleton variant="text" width={cellSize} /> : <Skeleton variant="text" />}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <>
      {settings.map(renderSkeleton)}
      {settings.map(renderSkeleton)}
      {settings.map(renderSkeleton)}
      {settings.map(renderSkeleton)}
      {settings.map(renderSkeleton)}
    </>
  );
};
