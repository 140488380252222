import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiAddressInfoResp, ApiContractInfo } from 'src/types/MultidataApi';
import { CONFIG } from 'src/app.config';
import { UiLink } from 'src/components/ui/UiLink';
import { CommonCode } from './contractCode/CommonCode';
import { VerifyCode } from './contractCode/VerifyCode';
import { ReadContract } from './ReadContract';
import { WriteContract } from './WriteContract';
import { UiTabs } from '../ui/UiTabs';
import { api } from 'src/api';

export const AddressContract = ({
  addressInfo,
  contractInfo,
  isLoading,
}: {
  addressInfo?: ApiAddressInfoResp;
  contractInfo?: ApiContractInfo;
  isLoading?: boolean;
}) => {
  const { address } = useParams();
  const navigate = useNavigate();

  const TABS = useMemo(() => {
    const hasProxyMethods =
      addressInfo?.has_methods_read_proxy && addressInfo?.has_methods_write_proxy;

    return contractInfo?.is_verified || contractInfo?.verified_twin_address_hash
      ? hasProxyMethods
        ? [
            {
              value: 'codeContract',
              label: 'Code',
              component: <VerifyCode contractInfo={contractInfo} />,
            },
            {
              value: 'readContract',
              label: 'Read Contract',
              component: (
                <ReadContract
                  addressInfo={addressInfo}
                  getContractReadMethods={api.multidata.getContractReadMethods}
                />
              ),
            },
            {
              value: 'readProxyContract',
              label: 'Read Proxy Contract',
              component: (
                <ReadContract
                  addressInfo={addressInfo}
                  getContractReadMethods={api.multidata.getContractReadProxyMethods}
                />
              ),
            },

            {
              value: 'writeContract',
              label: 'Write Contract',
              component: (
                <WriteContract
                  addressInfo={addressInfo}
                  contractInfo={contractInfo}
                  getContractWriteMethods={api.multidata.getContractWriteMethods}
                />
              ),
            },
            {
              value: 'writeProxyContract',
              label: 'Write Proxy Contract',
              component: (
                <WriteContract
                  addressInfo={addressInfo}
                  contractInfo={contractInfo}
                  getContractWriteMethods={api.multidata.getContractWriteProxyMethods}
                />
              ),
            },
          ]
        : [
            {
              value: 'codeContract',
              label: 'Code',
              component: <VerifyCode contractInfo={contractInfo} />,
            },
            {
              value: 'readContract',
              label: 'Read Contract',
              component: (
                <ReadContract
                  addressInfo={addressInfo}
                  getContractReadMethods={api.multidata.getContractReadMethods}
                />
              ),
            },
            {
              value: 'writeContract',
              label: 'Write Contract',
              component: (
                <WriteContract
                  addressInfo={addressInfo}
                  contractInfo={contractInfo}
                  getContractWriteMethods={api.multidata.getContractWriteMethods}
                />
              ),
            },
          ]
      : null;
  }, [contractInfo, addressInfo]);

  const [tab, setTab] = useState<string | null>(null);

  useEffect(() => {
    if (!TABS) return;
    setTab(TABS[0].value);
  }, [TABS]);

  if (isLoading) return <p>Loading...</p>;

  if (!contractInfo) return null;

  const verifyButton = (
    <Button
      onClick={() => {
        navigate(
          {
            pathname: `/${CONFIG.routes.addressPage}/${address}/contract_verification`,
          },
          {
            state: {
              contractInfo: contractInfo,
            },
          },
        );
      }}
      variant="contained"
      color="secondary"
      sx={{ minWidth: 'max-content' }}
    >
      Verify and publish
    </Button>
  );

  const hasShowCommonCode = (TABS && tab === TABS[0].value) || !tab;

  // TODO: add dinamic name from config
  const explorerName = 'Explorer';

  return (
    <Box>
      {contractInfo.verified_twin_address_hash && !contractInfo.is_verified && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
          mb={3}
        >
          <Box>
            <Typography>
              Contract is not verified. However, we found a verified contract with the same bytecode
              in {explorerName} DB{' '}
              <UiLink
                paragraph={false}
                variant="body2"
                to={`/${CONFIG.routes.addressPage}/${contractInfo.verified_twin_address_hash}?tab=contract`}
              >
                {contractInfo.verified_twin_address_hash}
              </UiLink>
              .
            </Typography>
            <Typography>
              All metadata displayed below is from that contract. In order to verify current
              contract, click Verify & Publish button
            </Typography>
          </Box>
          {verifyButton}
        </Stack>
      )}
      {(contractInfo.is_verified || contractInfo.verified_twin_address_hash) && (
        <>
          {TABS && tab && (
            <>
              <UiTabs
                tab={tab}
                setTab={setTab}
                tabs={TABS}
                withSearchParams={false}
                sx={{ mb: 4 }}
              />
              {TABS.map((el) => el.value === tab && <Box key={el.value}> {el.component} </Box>)}
            </>
          )}
        </>
      )}
      {hasShowCommonCode && <CommonCode contractInfo={contractInfo} verifyButton={verifyButton} />}
    </Box>
  );
};
