import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  alpha,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { Iconify } from 'src/components/Iconify';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiHash } from 'src/components/UiAddress';
import { useFetch } from 'src/hooks/useFetch';
import { ApiInternalTransactionsResp } from 'src/types/MultidataApi';
import { numberWithCommas } from 'src/utils/misc';
import { usePaginationWithSearchParams } from 'src/hooks/usePaginationWithSearchParams';
import { UiPagination } from 'src/components/ui/UiPagination';

export const AddressIntTxs = () => {
  const { address } = useParams();
  const {
    fetchData,
    isLoading,
    txStatus,
    data: txs,
  } = useFetch<ApiInternalTransactionsResp>('failed to get address txs: ');

  const { handlePageChange, page, paginationCount, prevPages, queryArgs } =
    usePaginationWithSearchParams(txs?.next_page_params || null);

  useEffect(() => {
    if (!address) return;
    fetchData(api.multidata.getAddressInternalTxs(address, queryArgs ? `?${queryArgs}` : ''));
  }, [address, queryArgs]);

  if (isLoading) return <p>Loading...</p>;

  if (txStatus.error) return <p>{txStatus.error}</p>;

  if (!txs) return null;

  return (
    <>
      <UiPagination
        page={page}
        paginationCount={paginationCount}
        handlePageChange={handlePageChange}
        disabled={isLoading}
        emptyPrevPages={prevPages.length === 0}
        sx={{ marginBottom: '1.5rem' }}
      />
      {txs.items.length > 0 ? (
        <TableContainer sx={{ overflowX: 'scroll' }}>
          <Table stickyHeader>
            <TableHeadCustom
              headLabel={[
                { id: 'status', label: 'Status' },
                { id: 'from', label: 'From' },
                { id: 'arrowIcon', label: '' },
                { id: 'to', label: 'To' },
                { id: 'value', label: 'Value', align: 'left' },
                { id: 'gasLimit', label: 'Gas limit', align: 'left' },
              ]}
            />
            <TableBody>
              {txs.items.map((tx, index) => (
                <TableRow key={index}>
                  <TableCell width={'1px'}>
                    {tx.success ? <Typography>Success</Typography> : <Typography>Error</Typography>}
                  </TableCell>
                  <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
                    <UiHash hash={tx.from.hash} name={tx.from.name} withCopy />
                  </TableCell>
                  <TableCell sx={{ width: '1px' }}>
                    <Iconify
                      color={(theme) => alpha(theme.palette.grey[500], 0.8)}
                      width={30}
                      icon={'material-symbols:arrow-circle-right-outline-rounded'}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
                    {tx.to ? <UiHash hash={tx.to.hash} name={tx.to.name} withCopy /> : '-'}
                  </TableCell>
                  <TableCell align="left" width="1rem">
                    <Typography>{tx.value}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{numberWithCommas(+tx.gas_limit)}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>There are no internal transactions for this address.</Typography>
      )}
    </>
  );
};
