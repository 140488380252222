import { FC } from 'react';
import { Typography, Grid } from '@mui/material';

import { CompilerField } from 'src/components/form/fields/CompilerField';
import { NightlyBuildsField } from 'src/components/form/fields/NightlyBuildsField';
import { ContractNameField } from 'src/components/form/fields/ContractNameField';
import { FetchAutomaticallyField } from 'src/components/form/fields/FetchAutomaticallyField';
import { UploadField } from 'src/components/form/fields/UploadField';

export const SolidityStandardForm: FC<{ compilers?: string[]; disabled?: boolean }> = ({
  compilers,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: 'text.disabled', mb: 1 }}>
          Contract verification via Solidity (standard JSON input)
        </Typography>
      </Grid>

      <ContractNameField disabled={disabled} />

      <NightlyBuildsField disabled={disabled} />

      <CompilerField compilers={compilers} withText disabled={disabled} />

      <UploadField
        text="Upload the standard input JSON file created during contract compilation."
        buttonLabel="Standard Input JSON"
        accept=".json"
        disabled={disabled}
      />

      <FetchAutomaticallyField />
    </>
  );
};
