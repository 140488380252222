import { CONFIG } from 'src/app.config';
import {
  ApiAccountsResp,
  ApiAddressCounters,
  ApiAddressInfoResp,
  ApiBlock,
  ApiBlocksResp,
  ApiCheckRedirect,
  ApiContractInfo,
  ApiContractLogsResp,
  ApiContractReadMethodResp,
  ApiContractMethod,
  ApiContractVerifacationConfig,
  ApiInternalTransactionsResp,
  ApiSearch,
  ApiStatsChartResp,
  ApiStatsResp,
  ApiTokenCounters,
  ApiTokenHoldersResp,
  ApiTokenInfo,
  ApiTokenInstance,
  ApiTokenTransfersResp,
  ApiTokensResp,
  ApiTransaction,
  ApiTransactionLogsResp,
  ApiTransactionsListResp,
  ApiVerifiedContactsCountersResp,
  ApiVerifiedContractsResp,
  VerificationMethodType,
  ApiAddressTokensResp,
  ApiNftInstances,
} from 'src/types/MultidataApi';
import { jsonFetch, jsonFetchFormData } from 'src/utils/jsonFetch';

async function search(queryString: string): Promise<ApiSearch> {
  return jsonFetch(`${CONFIG.service.api}/search${queryString}`);
}

async function checkRedirect(query: string): Promise<ApiCheckRedirect> {
  return jsonFetch(`${CONFIG.service.api}/search/check-redirect?q=${query}`);
}

async function getMainPageBlocks(): Promise<ApiBlock[]> {
  return jsonFetch(`${CONFIG.service.api}/main-page/blocks`);
}

async function getMainPageTxs(): Promise<ApiTransaction[]> {
  return jsonFetch(`${CONFIG.service.api}/main-page/transactions`);
}

async function getStatsCounters(): Promise<ApiStatsResp> {
  return jsonFetch(`${CONFIG.service.api}/stats`);
}

async function getTxsChart(): Promise<ApiStatsChartResp> {
  return jsonFetch(`${CONFIG.service.api}/stats/charts/transactions`);
}

async function getVerifiedContracts(queryString?: string): Promise<ApiVerifiedContractsResp> {
  return jsonFetch(`${CONFIG.service.api}/smart-contracts${queryString || ''}`);
}

async function getVerifiedContractsCounters(): Promise<ApiVerifiedContactsCountersResp> {
  return jsonFetch(`${CONFIG.service.api}/smart-contracts/counters`);
}

async function getContractInfo(address: string): Promise<ApiContractInfo> {
  return jsonFetch(`${CONFIG.service.api}/smart-contracts/${address}`);
}

async function getContractReadMethods(address: string): Promise<ApiContractMethod[]> {
  return jsonFetch(`${CONFIG.service.api}/smart-contracts/${address}/methods-read`);
}

async function getContractReadProxyMethods(address: string): Promise<ApiContractMethod[]> {
  return jsonFetch(`${CONFIG.service.api}/smart-contracts/${address}/methods-read-proxy`);
}

async function getContractWriteMethods(address: string): Promise<ApiContractMethod[]> {
  return jsonFetch(`${CONFIG.service.api}/smart-contracts/${address}/methods-write`);
}

async function getContractWriteProxyMethods(address: string): Promise<ApiContractMethod[]> {
  return jsonFetch(`${CONFIG.service.api}/smart-contracts/${address}/methods-write-proxy`);
}

async function queryReadMethod(address: string, data: any): Promise<ApiContractReadMethodResp> {
  return jsonFetchFormData(`${CONFIG.service.api}/smart-contracts/${address}/query-read-method`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

async function getContractVerificationConfig(): Promise<ApiContractVerifacationConfig> {
  return jsonFetch(`${CONFIG.service.api}/smart-contracts/verification/config`);
}

async function getTxs(queryString?: string): Promise<ApiTransactionsListResp> {
  return jsonFetch(`${CONFIG.service.api}/transactions${queryString}`);
}

async function getTxInfo(hash: string): Promise<ApiTransaction> {
  return jsonFetch(`${CONFIG.service.api}/transactions/${hash}`);
}

async function getInternalTxs(hash: string): Promise<ApiInternalTransactionsResp> {
  return jsonFetch(`${CONFIG.service.api}/transactions/${hash}/internal-transactions`);
}

async function getLogs(hash: string): Promise<ApiTransactionLogsResp> {
  return jsonFetch(`${CONFIG.service.api}/transactions/${hash}/logs`);
}

async function getRawTrace(hash: string): Promise<string> {
  return jsonFetch(`${CONFIG.service.api}/transactions/${hash}/raw-trace`);
}

async function getTxTokenTransfers(hash: string): Promise<ApiTokenTransfersResp> {
  return jsonFetch(`${CONFIG.service.api}/transactions/${hash}/token-transfers`);
}

async function getAccounts(queryString?: string): Promise<ApiAccountsResp> {
  return jsonFetch(`${CONFIG.service.api}/addresses${queryString || ''}`);
}

async function getAddressInfo(address: string): Promise<ApiAddressInfoResp> {
  return jsonFetch(`${CONFIG.service.api}/addresses/${address}`);
}

async function getAddressCounters(address: string): Promise<ApiAddressCounters> {
  return jsonFetch(`${CONFIG.service.api}/addresses/${address}/counters`);
}

async function getAddressInternalTxs(
  address: string,
  queryString?: string,
): Promise<ApiInternalTransactionsResp> {
  return jsonFetch(
    `${CONFIG.service.api}/addresses/${address}/internal-transactions${queryString || ''}`,
  );
}

async function getAddressLogs(address: string, queryString?: string): Promise<ApiContractLogsResp> {
  return jsonFetch(`${CONFIG.service.api}/addresses/${address}/logs${queryString || ''}`);
}

async function getAddressTokens(address: string): Promise<ApiAddressTokensResp> {
  return jsonFetch(`${CONFIG.service.api}/addresses/${address}/tokens`);
}

async function getAddressTxs(
  address: string,
  queryString?: string,
): Promise<ApiTransactionsListResp> {
  return jsonFetch(`${CONFIG.service.api}/addresses/${address}/transactions${queryString || ''}`);
}

async function getAddressTransfers(
  address: string,
  queryString?: string,
): Promise<ApiTokenTransfersResp> {
  return jsonFetch(
    `${CONFIG.service.api}/addresses/${address}/token-transfers${queryString || ''}`,
  );
}

async function getBlocks(queryString?: string): Promise<ApiBlocksResp> {
  return jsonFetch(`${CONFIG.service.api}/blocks${queryString || ''}`);
}

async function getTokens(queryString?: string): Promise<ApiTokensResp> {
  return jsonFetch(`${CONFIG.service.api}/tokens${queryString || ''}`);
}

async function getTokenCounters(address: string): Promise<ApiTokenCounters> {
  return jsonFetch(`${CONFIG.service.api}/tokens/${address}/counters`);
}

async function getTokenInfo(address: string): Promise<ApiTokenInfo> {
  return jsonFetch(`${CONFIG.service.api}/tokens/${address}`);
}
async function getTokenTransfers(address: string): Promise<ApiTokenTransfersResp> {
  return jsonFetch(`${CONFIG.service.api}/tokens/${address}/transfers`);
}

async function getTokenHolders(address: string): Promise<ApiTokenHoldersResp> {
  return jsonFetch(`${CONFIG.service.api}/tokens/${address}/holders`);
}

async function getNftInstances(address: string, queryString?: string): Promise<ApiNftInstances> {
  return jsonFetch(`${CONFIG.service.api}/tokens/${address}/instances${queryString || ''}`);
}

async function getTokenInstance(address: string, tokenId: string): Promise<ApiTokenInstance> {
  return jsonFetch(`${CONFIG.service.api}/tokens/${address}/instances/${tokenId}`);
}

async function getTransfersInstance(
  address: string,
  tokenId: string,
): Promise<ApiTokenTransfersResp> {
  return jsonFetch(`${CONFIG.service.api}/tokens/${address}/instances/${tokenId}/transfers/`);
}

async function getBlockInfo(blockHeight: string): Promise<ApiBlock> {
  return jsonFetch(`${CONFIG.service.api}/blocks/${blockHeight}`);
}

async function getBlockTxs(
  blockHeight: string,
  queryString?: string,
): Promise<ApiTransactionsListResp> {
  return jsonFetch(`${CONFIG.service.api}/blocks/${blockHeight}/transactions${queryString || ''}`);
}

async function verifyAndPublishContract(
  address: string,
  method: VerificationMethodType,
  data: { [key: string]: any } | FormData,
) {
  return data instanceof FormData
    ? jsonFetchFormData(
        `${CONFIG.service.api}/smart-contracts/${address}/verification/via/${method}`,
        {
          method: 'POST',
          body: data,
        },
      )
    : jsonFetch(`${CONFIG.service.api}/smart-contracts/${address}/verification/via/${method}`, {
        method: 'POST',
        body: data,
      });
}

export const multidata = {
  search,
  checkRedirect,
  getMainPageBlocks,
  getMainPageTxs,
  getStatsCounters,
  getTxsChart,
  getVerifiedContracts,
  getVerifiedContractsCounters,
  getContractInfo,
  getContractVerificationConfig,
  getContractReadMethods,
  getContractReadProxyMethods,
  getContractWriteMethods,
  getContractWriteProxyMethods,
  queryReadMethod,
  getTxs,
  getTxInfo,
  getInternalTxs,
  getLogs,
  getRawTrace,
  getTxTokenTransfers,
  getAccounts,
  getAddressInfo,
  getAddressCounters,
  getAddressInternalTxs,
  getAddressLogs,
  getAddressTxs,
  getAddressTokens,
  getAddressTransfers,
  getBlocks,
  getBlockInfo,
  getBlockTxs,
  verifyAndPublishContract,
  getTokens,
  getTokenInfo,
  getTokenCounters,
  getTokenTransfers,
  getTokenHolders,
  getNftInstances,
  getTokenInstance,
  getTransfersInstance,
};
