import { useContext, useEffect, useState } from 'react';
import { CHAINS } from 'src/constants/chains';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { ChainName } from 'src/types/Asset';
import { MulV1Feed } from 'src/types/MultidataV1Api';

const namesAllowed = CHAINS.map((chain) => chain.id);

export const useNetworkFeeds = (currentNetworkName: ChainName | undefined) => {
  const [networkFeeds, setNetworkFeeds] = useState<MulV1Feed[]>();

  const { feeds, isLoadingFeeds } = useContext(AssetsCtx);

  useEffect(() => {
    const waitForInitialization =
      isLoadingFeeds && currentNetworkName && namesAllowed.includes(currentNetworkName);

    if (waitForInitialization) return;

    const currentFeeds = feeds.filter((feed) => feed.name === currentNetworkName);

    setNetworkFeeds(currentFeeds);
  }, [currentNetworkName, feeds, isLoadingFeeds]);

  return { networkFeeds, isLoadingFeeds };
};
