import { InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
// components
import { Iconify } from 'src/components/Iconify';
import { useFetch } from 'src/hooks/useFetch';
import { ApiCheckRedirect, ApiSearch } from 'src/types/MultidataApi';

export const Searchbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchQuery, setSearchQuery] = useState('');
  const [query] = useSearchParams();

  const {
    fetchData,
    isLoading,
    data: searchResult,
  } = useFetch<ApiSearch>(`failed to search ${searchQuery}: `);

  const {
    fetchData: checkRedirect,
    isLoading: isLoadingRedirectInfo,
    data: redirect,
  } = useFetch<ApiCheckRedirect>(`failed to check redirect for ${searchQuery}: `);

  useEffect(() => {
    if (query.get('tab')) return;
    setSearchQuery(query.get('q') || '');
  }, [query]);

  const search = (query: string) => {
    const queryString = query ? `?q=${query}` : '';
    fetchData(api.multidata.search(queryString));
    if (!pathname.includes(CONFIG.routes.searchPage)) {
      checkRedirect(api.multidata.checkRedirect(query));
    }
  };

  function handleInput(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setSearchQuery(e.target.value);
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      search(searchQuery);
    }
  }

  useEffect(() => {
    if (!searchResult || isLoading || isLoadingRedirectInfo) return;

    if (searchResult && redirect?.redirect) {
      setSearchQuery('');
      const [parameter] = searchResult.items;

      if (parameter.type === 'transaction')
        navigate(`${CONFIG.routes.txPage}/${parameter.tx_hash}`);

      if (parameter.type === 'block')
        navigate(`${CONFIG.routes.blockPage}/${parameter.block_number}`);

      if (parameter.type === 'contract' || parameter.type === 'address')
        navigate(`${CONFIG.routes.addressPage}/${parameter.address}`);

      return;
    }

    navigate(
      {
        pathname: '/search-results',
        search: createSearchParams({ q: searchQuery }).toString(),
      },
      {
        state: {
          query: searchQuery,
          items: searchResult.items,
          nextPageParams: searchResult.next_page_params,
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLoadingRedirectInfo, redirect]);

  return (
    <TextField
      variant="standard"
      autoFocus
      placeholder="Search by Address"
      value={searchQuery}
      onKeyUp={handleKeyUp}
      onChange={handleInput}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      sx={{ width: { sm: '18rem', xs: '100%' }, pr: 3, alignSelf: 'center' }}
    />
  );
};
