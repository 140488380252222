import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as IconLogo } from 'src/assets/images/multidata_logo.svg';

export const Logo: FC<{ isMini?: boolean }> = ({ isMini }) => {
  const theme = useTheme();
  return (
    <Link to="/" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
      {isMini ? (
        <Box sx={{ py: 4 }}>
          <IconLogo width="100%" />
        </Box>
      ) : (
        <Box sx={{ py: 4, px: 3 }}>
          <IconLogo width="100%" />
        </Box>
      )}
    </Link>
  );
};
