import { Card, Stack, Typography } from '@mui/material';
import { StatusPoint } from 'src/components/StatusPoint';
import { MulV1FeedMetrics } from 'src/types/MultidataV1Api';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';
import { formatTimeStampUtc } from 'src/utils/time';

export const PriceCard = ({ asset }: { asset: MulV1FeedMetrics }) => {
  return (
    <Card
      sx={{
        width: { xs: '80vw', sm: '25rem' },
        p: 3,
        boxShadow: 'none',
        background: (theme) => theme.palette.background.neutral,
        '&:hover': {
          background: (theme) => theme.palette.grey[300],
        },
      }}
    >
      <Stack direction="row" alignItems="baseline" spacing={2}>
        <Typography variant="h4" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {asset.symbol}
        </Typography>
        <StatusPoint />
      </Stack>

      <Typography variant="h3" pt={1}>
        ${numberWithDividers(formatNumber.getDisplayFromQ112(asset.value, 0, 4))}{' '}
        {asset.currency === 'PCT' ? '%' : asset.currency || 'USD'}
      </Typography>
      <Typography variant="body2" pt={1}>
        {asset.updated ? formatTimeStampUtc(asset.updated) : ''}
      </Typography>
    </Card>
  );
};
