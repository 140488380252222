import { Box, Typography, useTheme } from '@mui/material';
import { ApiAddressToken } from 'src/types/MultidataApi';
import { CONFIG } from 'src/app.config';
import { numberWithDividers } from 'src/utils/number';
import { formatNumber } from 'src/utils/formatNumber';
import { Link } from 'react-router-dom';

export const TokensList = ({ tokens }: { tokens: ApiAddressToken[] }) => {
  const erc20Tokens = tokens.filter((item) => item.token.type === 'ERC-20');
  const erc721Tokens = tokens.filter((item) => item.token.type === 'ERC-721');
  const erc1155Tokens = tokens.filter((item) => item.token.type === 'ERC-1155');

  return (
    <Box
      sx={{
        position: 'relative',
        width: '16.5rem',
        maxHeight: '28.125rem',
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          padding: '1rem',
          '& section:not(:nth-of-type(1))': { marginTop: '1.5rem' },
        }}
      >
        {erc20Tokens.length > 0 && (
          <TokensSection title={`ERC-20 tokens (${erc20Tokens.length})`} tokens={erc20Tokens} />
        )}
        {erc721Tokens.length > 0 && (
          <TokensSection title={`ERC-721 tokens (${erc721Tokens.length})`} tokens={erc721Tokens} />
        )}
        {erc1155Tokens.length > 0 && (
          <TokensSection
            title={`ERC-1155 tokens (${erc1155Tokens.length})`}
            tokens={erc1155Tokens}
          />
        )}
      </Box>
    </Box>
  );
};

const TokensSection = ({ title, tokens }: { title: string; tokens: ApiAddressToken[] }) => {
  const theme = useTheme();

  return (
    <Box component="section">
      <Typography variant="subtitle1" sx={{ color: theme.palette.grey[500] }}>
        {title}
      </Typography>
      <Box component="ul" sx={{ listStyle: 'none' }}>
        {tokens.map((token) => (
          <TokenInfo key={token.token.address} token={token} />
        ))}
      </Box>
    </Box>
  );
};

const TokenInfo = ({ token }: { token: ApiAddressToken }) => {
  const { token: tokenInfo } = token;
  const theme = useTheme();

  return (
    <Box
      component="li"
      key={tokenInfo.address}
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        '& a:first-of-type': {
          color: `${theme.palette.text.primary}`,
        },
        '& a:first-of-type:hover': {
          background: theme.palette.primary.lighter,
        },
      }}
    >
      <Link
        to={`/${CONFIG.routes.tokenPage}/${tokenInfo.address}`}
        style={{
          display: 'block',
          padding: '0.45rem 0.15rem',
          textDecoration: 'none',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{tokenInfo.name}</Typography>
        <Typography
          sx={{
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {numberWithDividers(
            formatNumber.getDisplay(token.value, parseInt(tokenInfo.decimals || '0'), 4, false),
          )}{' '}
          {tokenInfo.symbol && `(${tokenInfo.symbol})`}
        </Typography>
      </Link>
    </Box>
  );
};
