import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AccordionDetails, Box, Typography, useTheme } from '@mui/material';
import { useFetch } from 'src/hooks/useFetch';
import { UiHash } from 'src/components/UiAddress';
import { UiAccordion, UiAccordionSummary } from 'src/components/ui/UiAccordion';
import { ApiAddressInfoResp, ApiContractMethod } from 'src/types/MultidataApi';
import { ProxyContractMessage } from './ProxyContractMessage';
import { ReadForm } from './contractForm/ReadForm';

export const ReadContract = ({
  addressInfo,
  getContractReadMethods,
}: {
  addressInfo?: ApiAddressInfoResp;
  getContractReadMethods: (address: string) => Promise<ApiContractMethod[]>;
}) => {
  const { address } = useParams();
  const theme = useTheme();

  const {
    fetchData,
    isLoading,
    txStatus,
    data: readMethods,
  } = useFetch<ApiContractMethod[]>('failed to get read methods: ');

  useEffect(() => {
    if (!address) return;
    fetchData(getContractReadMethods(address));
  }, [address]);

  if (isLoading) return <p>Loading...</p>;
  if (txStatus.error) return <p>{txStatus.error}</p>;
  if (!readMethods) return null;

  return (
    <Box>
      {addressInfo?.implementation_address && (
        <ProxyContractMessage
          address={addressInfo.implementation_address}
          name={addressInfo.implementation_name}
        />
      )}
      {readMethods.map((method, index) => {
        if (method.type === 'function') {
          return (
            <Box key={method.method_id} mb="1rem">
              <UiAccordion>
                <UiAccordionSummary
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography>
                    {index + 1}. {method.name}
                  </Typography>
                </UiAccordionSummary>
                <AccordionDetails>
                  {method.error && <Typography color="error">ERROR: {method.error}</Typography>}
                  {method.inputs.length > 0 && (
                    <ReadForm
                      method={method}
                      contractType={addressInfo?.has_methods_read_proxy ? 'proxy' : 'regular'}
                    />
                  )}
                  {method.outputs.map((output, i) => {
                    const isTypeAddress = output.type === 'address';

                    if (isTypeAddress && output.value) {
                      return (
                        <Box key={i}>
                          <UiHash hash={output.value} sx={{ display: 'inline-block' }} />
                          <Typography
                            component="i"
                            sx={{ fontSize: '0.825rem', color: `${theme.palette.grey[600]}` }}
                          >
                            {' '}
                            {output.type}
                          </Typography>
                        </Box>
                      );
                    }

                    return (
                      output.hasOwnProperty('value') &&
                      JSON.stringify(output.value).length > 0 && (
                        <Typography key={i} sx={{ fontSize: '0.875rem', wordWrap: 'break-word' }}>
                          {!!output.value ? output.value : JSON.stringify(output.value)}{' '}
                          {output.type && (
                            <Typography
                              component="i"
                              sx={{ fontSize: '0.825rem', color: `${theme.palette.grey[600]}` }}
                            >
                              {output.type}
                            </Typography>
                          )}
                        </Typography>
                      )
                    );
                  })}
                </AccordionDetails>
              </UiAccordion>
            </Box>
          );
        }
      })}
    </Box>
  );
};
