import {
  Box,
  CardProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Iconify } from 'src/components/Iconify';
import { Label } from 'src/components/label/Label';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiHash } from 'src/components/UiAddress';
import { useFetch } from 'src/hooks/useFetch';
import { usePaginationWithSearchParams } from 'src/hooks/usePaginationWithSearchParams';
import useResponsive from 'src/hooks/useResponsive';
import { useContractChainMap } from 'src/providers/ContractChainMapProvider';
import { ApiTransactionsListResp } from 'src/types/MultidataApi';
import { timeSince } from 'src/utils/time';
import { NewTxsNotification } from 'src/components/NewTxsNotification';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { UiPagination } from 'src/components/ui/UiPagination';

const TABLE_HEAD_LABELS = [
  { id: 'txnHash', label: 'Txn hash' },
  { id: 'interact', label: 'Interacted with' },
  { id: 'status', label: 'Status' },
  { id: 'from', label: 'From' },
  { id: 'arrowIcon', label: '' },
  { id: 'to', label: 'To' },
  { id: 'type', label: 'Type' },
  { id: 'method', label: 'Method' },
  { id: 'block', label: 'Block', sx: { width: '10rem' } },
];

interface Props {
  title?: string;
  fetchTransactions: (query: string) => Promise<ApiTransactionsListResp>;
  address?: string;
  disabledNotification?: boolean;
}

export const TxsTable: FC<CardProps & Props> = ({
  title,
  fetchTransactions,
  address,
  disabledNotification = false,
  ...others
}) => {
  const theme = useTheme();
  const isDownXl = useResponsive('down', 'xl');
  const isDownMd = useResponsive('down', 'md');

  const {
    fetchData,
    txStatus,
    isLoading,
    data: transactions,
  } = useFetch<ApiTransactionsListResp>('failed to get blocks information: ');

  const { getWriteChain } = useContractChainMap();

  const navigate = useNavigate();

  const { participants } = useContext(AssetsCtx);

  const { handlePageChange, page, paginationCount, prevPages, queryArgs } =
    usePaginationWithSearchParams(transactions?.next_page_params || null);

  useEffect(() => {
    fetchData(fetchTransactions(queryArgs ? `?${queryArgs}` : ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryArgs]);

  if (isLoading) return <p>Loading...</p>;

  if (txStatus.error) return <p>{txStatus.error}</p>;

  return (
    <Box {...others}>
      <UiPagination
        page={page}
        paginationCount={paginationCount}
        handlePageChange={handlePageChange}
        disabled={isLoading}
        emptyPrevPages={prevPages.length === 0}
        sx={{ marginBottom: 3 }}
      />
      <>
        <Typography variant="h6" sx={{ my: 3 }}>
          {title}
        </Typography>
        {transactions && transactions?.items.length > 0 ? (
          <TableContainer
            sx={{
              '& th:first-of-type, td:first-of-type': {
                paddingLeft: 3,
              },
            }}
          >
            <Table stickyHeader sx={{ overflowX: 'scroll' }}>
              <TableHeadCustom headLabel={TABLE_HEAD_LABELS} />
              {isLoading ? (
                <TableBody>
                  <tr>
                    <td colSpan={TABLE_HEAD_LABELS.length}>
                      <p>Loading...</p>
                    </td>
                  </tr>
                </TableBody>
              ) : (
                <TableBody>
                  {page === 1 && !disabledNotification && (
                    <NewTxsNotification
                      onCounterClick={() => navigate(0)}
                      address={address}
                      forTable
                    />
                  )}
                  {transactions?.items.map((tx) => (
                    <TableRow key={tx.hash}>
                      <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>
                        <Box>
                          <UiHash
                            variant="subtitle1"
                            hash={tx.hash}
                            cuttingOptions={isDownMd ? [4, 4] : isDownXl ? [10, 10] : [18, 18]}
                            cutting
                            withCopy
                          />
                          {tx.timestamp && (
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: 'text.secondary' }}
                            >
                              {timeSince(Number(new Date(tx.timestamp)))} ago
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        {getWriteChain(tx.to?.hash) ? (
                          <Label variant="filled" color="primary">
                            {getWriteChain(tx.to?.hash)}
                          </Label>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>
                        <Label
                          variant="soft"
                          color={tx.status === 'ok' ? 'success' : 'error'}
                          startIcon={
                            <Iconify
                              icon={
                                tx.status === 'ok'
                                  ? 'clarity:success-standard-solid'
                                  : 'material-symbols:error-rounded'
                              }
                              color={(theme) =>
                                tx.status === 'ok'
                                  ? theme.palette.success.main
                                  : theme.palette.error.main
                              }
                              mr="0.5rem"
                            />
                          }
                        >
                          {tx.status === 'ok'
                            ? 'Success'
                            : tx.result === 'pending'
                            ? 'Pending'
                            : 'Error'}
                        </Label>
                      </TableCell>
                      <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>
                        <UiHash
                          hash={tx.from.hash}
                          name={tx.from.name}
                          cutting
                          withCopy
                          cuttingOptions={isDownMd ? [4, 4] : isDownXl ? [8, 8] : [10, 10]}
                          isContract={tx.from.is_contract}
                          isValidator={!!participants[tx.from.hash]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>
                        <Iconify
                          color={alpha(theme.palette.grey[500], 0.8)}
                          width={30}
                          icon={'material-symbols:arrow-circle-right-outline-rounded'}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '1px', verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                        {tx.to ? (
                          <UiHash
                            hash={tx.to.hash}
                            name={tx.to.name}
                            cutting
                            withCopy
                            cuttingOptions={isDownMd ? [4, 4] : isDownXl ? [8, 8] : [10, 10]}
                            isContract={tx.to.is_contract}
                            isValidator={!!participants[tx.to.hash]}
                          />
                        ) : tx.created_contract ? (
                          <UiHash
                            hash={tx.created_contract.hash}
                            name={tx.created_contract.name}
                            sx={{ whiteSpace: 'nowrap' }}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>
                        {tx.tx_types && tx.tx_types[0] && (
                          <Label mb={1} color="info">
                            {tx.tx_types[0]}
                          </Label>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>
                        <Label color="info">{tx.method || '-'}</Label>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        <UiHash hash={tx.block as number} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        ) : (
          <Typography>There are no transactions.</Typography>
        )}
        <UiPagination
          page={page}
          paginationCount={paginationCount}
          handlePageChange={handlePageChange}
          disabled={isLoading}
          emptyPrevPages={prevPages.length === 0}
          sx={{ marginTop: 3 }}
        />
      </>
    </Box>
  );
};
