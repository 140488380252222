import { Theme, Typography, useTheme } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';

import { TabsType, UiTabs } from 'src/components/ui/UiTabs';
import { OracleType, oracleTypeMap } from 'src/constants/oracle';
import { useNetworkFeeds } from 'src/hooks/useNetworkFeeds';
import { ChainName } from 'src/types/Asset';

type Props = {
  tab?: OracleType;
  setTab: (v: OracleType | undefined) => void;
  currentNetwork: ChainName;
  isDisabled?: boolean;
};

const styleOverrides = {
  paper: ({
    selected,
    disabled,
    theme,
  }: {
    selected?: boolean;
    disabled?: boolean;
    theme: Theme;
  }) => ({
    background: selected ? theme.palette.grey[300] : theme.palette.background.neutral,
    minWidth: '8rem',
    height: '100%',
    display: 'grid',
    gridGap: '0.5rem',
    justifyItems: 'center',
    alignItems: 'flex-end',
    p: 2,
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.grey[300],
      border: 'none',
    },
  }),
};

export const OracleTypeTabs = memo(function OracleTypeTabs({
  tab,
  setTab,
  currentNetwork,
  isDisabled,
}: Props) {
  const theme = useTheme();
  const [feedTypes, setFeedTypes] = useState<OracleType[]>();
  const { networkFeeds } = useNetworkFeeds(currentNetwork);

  const TABS = useMemo<TabsType<OracleType> | undefined>(() => {
    if (!feedTypes) return;

    return feedTypes.map((type) => ({
      label: oracleTypeMap[type],
      value: type,
    }));
  }, [feedTypes]);

  useEffect(() => {
    if (!networkFeeds) return;
    const feedTypes = networkFeeds.map((feed) => feed.type).sort();
    setFeedTypes(feedTypes);
  }, [networkFeeds]);

  if (!TABS) return null;

  return (
    <>
      <Typography variant="h6" mb="1rem" mt="1.5rem">
        Oracle type
      </Typography>
      <UiTabs<OracleType | undefined>
        tabs={TABS}
        tab={tab}
        setTab={setTab}
        disabled={isDisabled}
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        scrollButtons={false}
        tabSx={{
          fontWeight: 'bold',
          borderRadius: '8px',
          '&.MuiButtonBase-root': {
            marginRight: '1rem',
          },
          '&.Mui-selected': {
            background: theme.palette.grey[300],
          },
          ...styleOverrides.paper({
            theme,
            disabled: isDisabled,
          }),
        }}
      />
    </>
  );
});
