import { Grid, Link, Stack, Typography } from '@mui/material';

import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from 'src/api';
import { Iconify } from 'src/components/Iconify';
import { IconContract } from 'src/components/Icons';
import { PageTitle } from 'src/components/PageTitle';
import { UiGridContainer, UiGridItem } from 'src/components/ui/UiGrid';
import { UiInfoTooltip } from 'src/components/ui/UiInfoTooltip';
import { UiProgress } from 'src/components/ui/UiProgress';
import { DEFAULT_CHAIN_ID } from 'src/constants/chains';
import { STABLECOINS } from 'src/constants/collaterals';
import { OracleType, oracleTypeMap } from 'src/constants/oracle';
import { useFeed } from 'src/hooks/useFeed';
import { useFetch } from 'src/hooks/useFetch';
import { useNetworkAssets } from 'src/hooks/useNetworkAssets';
import { useNetworkParamsById } from 'src/hooks/useNetworkParamsById';
import useResponsive from 'src/hooks/useResponsive';
import { useTimePast } from 'src/hooks/useTimePast';
import { PriceHistoryChart } from 'src/pages/oracles/[networkId]/[oracleType]/[address]/components/PriceHistoryChart';
import { ValidatorCard } from 'src/pages/oracles/[networkId]/[oracleType]/[address]/components/ValidatorCard';
import { ChainName } from 'src/types/Asset';
import { MetricValues, MulV1HistoryPrices } from 'src/types/MultidataV1Api';
import { spaceBetweenCell } from 'src/utils/cssStyles';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';
import { formatTimeStampUtc } from 'src/utils/time';
import { shortenHash } from 'src/utils/ui';

export const Asset = () => {
  const {
    address,
    networkId = DEFAULT_CHAIN_ID,
    oracleType,
  } = useParams<{
    address: string;
    networkId: ChainName;
    oracleType: OracleType;
  }>();

  const isMobile = useResponsive('down', 'sm');
  const navigate = useNavigate();

  const { feedForView, dataFeedForView, currentFeed } = useFeed(networkId, oracleType);
  const { networkName, networkExplorer } = useNetworkParamsById(currentFeed?.name);
  const { networkExplorer: helperNetworkExplorer } = useNetworkParamsById(feedForView?.name);

  const { networkAssets, isLoading: isNetworkAssetsLoading } = useNetworkAssets(
    dataFeedForView?.network,
    dataFeedForView?.feedAddress,
  );

  const {
    fetchData,
    data: validators,
    isLoading: isLoadingValidators,
  } = useFetch<MetricValues[]>('failed to get validators by asset: ');

  const {
    fetchData: fetchHistoryPrice,
    txStatus: historyPriceStatus,
    data: historyPrices,
    isLoading: isHistoryPricesLoading,
  } = useFetch<MulV1HistoryPrices[]>('failed to get stats information: ');

  const currentAsset = useMemo(
    () => (networkAssets ? networkAssets['all']?.find((asset) => asset.name === address) : null),
    [address, networkAssets],
  );

  useEffect(() => {
    if (!networkId || !feedForView || !address || !validators) return;

    fetchHistoryPrice(
      api.multidataV1.getHistoryPricesForMetric(feedForView.name, feedForView.address, address),
    );
  }, [address, feedForView, networkId, validators]);

  useEffect(() => {
    if (!feedForView || !currentAsset) return;

    fetchData(
      api.multidataV1.getChainFeedsMetricValues(
        feedForView.name,
        feedForView.address,
        currentAsset.name,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedForView, currentAsset]);

  const lastUpdated =
    currentAsset && currentAsset.updated ? formatTimeStampUtc(currentAsset.updated) : '';
  const timePast = useTimePast(new Date(currentAsset?.updated || '').getTime());
  const currency = currentAsset?.currency === 'PCT' ? '%' : currentAsset?.currency || 'USD';

  if (isNetworkAssetsLoading) return <UiProgress text="Loading asset..." />;

  if (!networkAssets || !currentAsset) return null;

  return (
    <>
      <PageTitle
        title={`${currentAsset?.symbol}/${currentAsset.currency}`}
        path={`/oracles/${networkId}`}
        tooltip="Back to data feeds"
        mt={5}
        mb={2}
      />

      <Grid container spacing={2}>
        <Grid item>
          <UiGridContainer mb={3} sx={{ display: { xs: 'grid' } }}>
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="The answer is calculated based on price feeds from multiple on-chain and off-chain sources like decentralized exchanges (DEXs), centralized exchanges (CEXs), and third-party APIs." />
              <Typography>Answer</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography variant="h6">
                {numberWithDividers(formatNumber.getDisplayFromQ112(currentAsset.value, 0, 4))}{' '}
                {currency}
              </Typography>
            </UiGridItem>

            {networkName && (
              <>
                <UiGridItem>Network</UiGridItem>
                <UiGridItem>{networkName}</UiGridItem>
              </>
            )}

            {currentFeed?.address && (
              <>
                <UiGridItem>Oracle address</UiGridItem>
                <UiGridItem>
                  {currentFeed?.address && (
                    <Stack direction="row" alignItems="center">
                      <IconContract />
                      <Link
                        variant="body2"
                        href={`${networkExplorer}/address/${currentFeed.address}`}
                        target="_blank"
                        rel="noopener"
                        underline="none"
                      >
                        {isMobile ? shortenHash(currentFeed.address) : currentFeed.address}
                      </Link>
                    </Stack>
                  )}
                </UiGridItem>
              </>
            )}

            {currentFeed?.verifying_contract && (
              <>
                <UiGridItem>Helper address</UiGridItem>
                <UiGridItem>
                  {feedForView?.address && (
                    <Stack direction="row" alignItems="center">
                      <IconContract />
                      <Link
                        variant="body2"
                        href={`${helperNetworkExplorer}/address/${feedForView.address}`}
                        target="_blank"
                        rel="noopener"
                        underline="none"
                      >
                        {isMobile ? shortenHash(feedForView.address) : feedForView.address}
                      </Link>
                    </Stack>
                  )}
                </UiGridItem>
              </>
            )}

            {currentFeed && oracleTypeMap[currentFeed.type] && (
              <>
                <UiGridItem>Oracle type</UiGridItem>
                <UiGridItem>{oracleTypeMap[currentFeed.type]}</UiGridItem>
              </>
            )}

            <UiGridItem>Asset Name</UiGridItem>
            <UiGridItem>{currentAsset.description}</UiGridItem>

            <UiGridItem>Asset Class</UiGridItem>
            <UiGridItem>{STABLECOINS.includes(currentAsset.name) ? 'Stable' : 'Token'}</UiGridItem>

            <UiGridItem>Tier</UiGridItem>
            <UiGridItem>
              <Iconify
                icon="clarity:success-standard-solid"
                color={(theme) => theme.palette.success.main}
              />
            </UiGridItem>

            <UiGridItem>Last update</UiGridItem>
            <UiGridItem>{lastUpdated}</UiGridItem>

            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Time since last answer was written on-chain" />
              <Typography>Time past</Typography>
            </UiGridItem>
            <UiGridItem>{timePast} ago</UiGridItem>
          </UiGridContainer>
        </Grid>
        <Grid item xs={12} lg={7}>
          {historyPrices ? (
            <PriceHistoryChart data={historyPrices} currency={currency} />
          ) : (
            historyPriceStatus.error && (
              <Typography variant="subtitle2" textAlign={{ desktop: 'center' }}>
                Problem with loading chart data
              </Typography>
            )
          )}
        </Grid>
      </Grid>

      {isLoadingValidators ? (
        <UiProgress text="Loading validators..." />
      ) : (
        <>
          <Typography variant="h6" mt={4}>
            Validators
          </Typography>
          <Grid container spacing={4} pt={3}>
            {validators &&
              validators
                .sort((validator) => (!validator.source_prices ? 1 : -1))
                .map((validator) => (
                  <Grid
                    item
                    key={validator.validator}
                    xs={12}
                    lg={4}
                    onClick={() => navigate(`validator/${validator.validator}`)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ValidatorCard
                      validator={validator}
                      currentAsset={currentAsset}
                      timePast={timePast}
                      networkId={feedForView?.name}
                    />
                  </Grid>
                ))}
          </Grid>
        </>
      )}
    </>
  );
};
