import { Box, Card, CardContent, List, ListItem, Stack } from '@mui/material';
import { useContext, useMemo } from 'react';
import { UiLink } from 'src/components/ui/UiLink';
import { UiProgress } from 'src/components/ui/UiProgress';
import { CHAINS } from 'src/constants/chains';
import { AssetsCtx } from 'src/providers/AssetsProvider';

const styleOverrides = {
  icon: {
    minHeight: '3rem',
    '& svg': {
      height: '3rem',
      width: 'auto',
      maxWidth: '100px',
    },
  },
};

export const Oracles = () => {
  const { feeds, isLoadingFeeds } = useContext(AssetsCtx);

  const networks = useMemo(() => {
    if (isLoadingFeeds) return;
    return CHAINS.filter((chain) => feeds.map((feed) => feed.name).includes(chain.id));
  }, [feeds, isLoadingFeeds]);

  return (
    <>
      {isLoadingFeeds ? (
        <UiProgress text="Loading feeds..." />
      ) : (
        <List>
          {networks?.map((network) => (
            <ListItem key={network.id} disableGutters>
              <UiLink to={network.id} width="100%" color="text.primary">
                <Card
                  sx={{
                    boxShadow: 'none',
                    background: (theme) => theme.palette.background.neutral,
                  }}
                >
                  <CardContent>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Box sx={styleOverrides.icon}>{network.icon}</Box>
                      {network.id !== 'optimism' && network.id !== 'chunk' && (
                        <span>{network.name}</span>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </UiLink>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};
