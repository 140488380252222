import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from 'src/api';
import { AssetType, ChainName } from 'src/types/Asset';
import { MulV1FeedMetrics } from 'src/types/MultidataV1Api';
import { getAssetTypeByName } from 'src/utils/getAssetType';

import { useFetch } from './useFetch';

export type NetworkAssets = Record<AssetType, MulV1FeedMetrics[]>;

export const useNetworkAssets = (
  network: string | ChainName | undefined,
  feedAddress: string | undefined,
) => {
  const [networkAssets, setNetworkAssets] = useState<NetworkAssets>();
  const { fetchData, isLoading } = useFetch<MulV1FeedMetrics[]>('failed to get feed metrics: ');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchDataAndFillStore() {
      if (!network || !feedAddress) return;
      const resp = await fetchData(api.multidataV1.getFeedsMetrics(network, feedAddress));

      if (!resp) {
        navigate('/');
        return;
      }

      const assets = {} as NetworkAssets;

      resp.forEach((elem) => {
        const type = getAssetTypeByName(elem.address);

        elem.symbol =
          type === 'tokens'
            ? elem.symbol
            : `${elem.description} (${elem.symbol.split('::').pop()})`;

        if (type === 'tokens' && elem.address.length !== 42) elem.address = '';
        if (type !== 'tokens') elem.address = '';

        const allType = assets['all'];

        if (allType) {
          allType.push(elem);
        } else {
          assets['all'] = [elem];
        }
      });

      setNetworkAssets(assets);
    }

    fetchDataAndFillStore();
  }, [feedAddress, network]);

  return { networkAssets, isLoading };
};
