import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { UiDivider } from '../ui/UiDivider';
import TableHeadCustom from '../table/TableHeadCustom';
import { DecodedInputLog, RevertReason } from 'src/types/MultidataApi';

export const DisplayMethod = ({ method }: { method: RevertReason | DecodedInputLog }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 'bold', marginRight: '1rem' }}>Method Id</Typography>
        <Typography>{method.method_id}</Typography>
      </Box>
      <UiDivider />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 'bold', marginRight: '1rem' }}>Call</Typography>
        <Typography>{method.method_call}</Typography>
      </Box>
      {method.parameters.length > 0 && (
        <TableContainer sx={{ overflow: 'unset', marginTop: '1rem' }}>
          <Table stickyHeader>
            <TableHeadCustom
              headLabel={[
                { id: 'name', label: 'Name' },
                { id: 'type', label: 'Type' },
                { id: 'data', label: 'Data' },
              ]}
            />
            <TableBody>
              {method.parameters.map(({ name, type, value }, index) => (
                <TableRow key={index}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{type}</TableCell>
                  <TableCell sx={{ overflowWrap: 'anywhere' }}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
