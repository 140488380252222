import { Box, BoxProps, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useState } from 'react';
import Chart, { useChart } from 'src/components/chart';
import { MulV1HistoryPrices } from 'src/types/MultidataV1Api';
import { formatNumber } from 'src/utils/formatNumber';

type Props = {
  data: MulV1HistoryPrices[];
  currency?: string;
  onClickHandler?: (epochId: number) => void;
};

export const PriceHistoryChart = ({
  data,
  currency = 'USD',
  onClickHandler,
  ...props
}: Props & ApexCharts.ApexOptions & BoxProps) => {
  const theme = useTheme();

  const [selectedPoint, setSelectedPoint] = useState<MulV1HistoryPrices>();

  const chartOptions = useChart({
    colors: [theme.palette.text.primary],
    annotations: selectedPoint
      ? {
          xaxis: [
            {
              x: selectedPoint.epoch_id,
              borderColor: theme.palette.grey[400],
            },
          ],
          points: [
            {
              x: selectedPoint.epoch_id,
              y: Number(selectedPoint.value),
              marker: {
                size: 4,
                fillColor: theme.palette.text.primary,
                strokeColor: theme.palette.text.primary,
                radius: 2,
              },
              label: {
                borderColor: theme.palette.grey[400],
                text: `Price: ${Number(
                  formatNumber.getDisplayFromQ112(selectedPoint.value, 0, 4),
                )} ${currency}`,
                offsetY: -8,
                style: {
                  background: 'rgba(244, 246, 248, 0.95)',
                  color: theme.palette.text.primary,
                  padding: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10,
                  },
                },
              },
            },
          ],
        }
      : {},
    tooltip: {
      y: {
        formatter: (val) => `${formatNumber.getDisplayFromQ112(val, 0, 2)} ${currency}`,
      },
    },
    xaxis: {
      type: 'datetime',
      categories: data?.map((el) => el.epoch_id),
      labels: {
        rotate: 0,
        formatter: (value) =>
          moment(Number(value) * 1000)
            .utc()
            .format('MMM DD h:mma'),
      },
    },
    yaxis: {
      tickAmount: 11,
      labels: {
        formatter: (val) => formatNumber.getDisplayFromQ112(val, 0, 4),
      },
    },
    stroke: {
      width: 2,
    },
    chart: {
      events: {
        markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
          setSelectedPoint(data?.[dataPointIndex]);
          onClickHandler?.(data?.[dataPointIndex].epoch_id);
        },
      },
    },
    ...props,
  });

  return (
    <Card style={{ height: '100%', boxShadow: 'none' }}>
      <Box
        sx={{
          mt: 3,
          height: { xs: '250px', md: '366px' },
          ...props.sx,
        }}
      >
        <Chart
          height="94%"
          type="line"
          options={chartOptions}
          series={[
            {
              name: 'Price',
              data: data.map((el) => Number(el.value)),
            },
          ]}
        />
      </Box>
    </Card>
  );
};
