import { Navigate, Route, Routes } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import { CONFIG } from 'src/app.config';
import { MainLayout } from 'src/layout/MainLayout';
import { Accounts } from 'src/pages/Accounts';

import { Address } from 'src/pages/address/[address]/Address';
import { ContractVerification } from 'src/pages/address/[address]/ContractVerification';

import { BlockDetails } from 'src/pages/block/[blockHeight]/BlockDetails';
import { Blocks } from 'src/pages/Blocks';
import { Home } from 'src/pages/Home';
import { NetworkId } from 'src/pages/NetworkId';
import { Asset } from 'src/pages/oracles/[networkId]/[oracleType]/[address]/Asset';
import { Validator } from 'src/pages/oracles/[networkId]/[oracleType]/[address]/validator/[validator]/Validator';
import { SelectNetwork } from 'src/pages/oracles/[networkId]/SelectNetwork';
import { Oracles } from 'src/pages/oracles/Oracles';
import { SearchResults } from 'src/pages/SearchResults';
import { TokenInstance } from 'src/pages/token/[address]/instance/TokenInstance';
import { TokenDetails } from 'src/pages/token/[address]/token-details';
import { Tokens } from 'src/pages/Tokens';
import { Transactions } from 'src/pages/Transactions';
import { Transaction } from 'src/pages/tx/[hash]/Transaction';
import { AggregatorAssets } from 'src/pages/aggregatorAssets/AggregatorAssets';
import { VerifiedContracts } from 'src/pages/VerifiedContracts';
import { AssetsCtxProvider } from 'src/providers/AssetsProvider';
import { ContractChainMapProvider } from 'src/providers/ContractChainMapProvider';
import { SettingsCtxProvider } from 'src/providers/SettingsProvider';
import { WebsocketProvider } from 'src/providers/WebsocketProvider';

export const App = () => {
  return (
    <div style={{ height: '100%' }}>
      <SettingsCtxProvider>
        <AssetsCtxProvider>
          <WebsocketProvider>
            <ContractChainMapProvider>
              <Routes>
                <Route element={<MainLayout />}>
                  <Route element={<Home />} path="/" />
                  <Route path={CONFIG.routes.txsPage} element={<Transactions />} />
                  <Route path={CONFIG.routes.aggregatorAssets} element={<AggregatorAssets />} />
                  <Route path={CONFIG.routes.txPage}>
                    <Route path=":hash" element={<Transaction />} />
                  </Route>
                  <Route path={CONFIG.routes.blocksPage} element={<Blocks />} />
                  <Route path={CONFIG.routes.blockPage}>
                    <Route path=":blockHeight" element={<BlockDetails />} />
                  </Route>
                  <Route path={CONFIG.routes.addressPage}>
                    <Route index element={<Navigate to="/" replace />} />
                    <Route path=":address">
                      <Route index element={<Address />} />
                      <Route path="contract_verification" element={<ContractVerification />} />
                    </Route>
                  </Route>
                  <Route path={CONFIG.routes.tokensPage} element={<Tokens />} />
                  <Route path={CONFIG.routes.tokenPage}>
                    <Route path=":address" element={<TokenDetails />} />
                    <Route path=":address/instance/:tokenId" element={<TokenInstance />} />
                  </Route>
                  <Route path={CONFIG.routes.accounts} element={<Accounts />} />
                  <Route
                    path={CONFIG.routes.verifiedContractsPage}
                    element={<VerifiedContracts />}
                  />
                  <Route path="/search-results" element={<SearchResults />} />
                  <Route path={CONFIG.routes.oraclesPage}>
                    <Route index element={<Oracles />} />
                    <Route path=":networkId" element={<SelectNetwork />}>
                      <Route index element={<NetworkId />} />
                      <Route path=":oracleType/:address" element={<Asset />} />
                    </Route>
                    <Route
                      path=":networkId/:oracleType/:address/validator/:validator"
                      element={<Validator />}
                    />
                  </Route>
                </Route>
              </Routes>
            </ContractChainMapProvider>
          </WebsocketProvider>
        </AssetsCtxProvider>
      </SettingsCtxProvider>
    </div>
  );
};
