import { useMemo } from 'react';
import { CHAINS } from 'src/constants/chains';

export const useNetworkParamsById = (networkId: string | undefined) => {
  const chainParams = useMemo(() => {
    if (!networkId) return;

    return CHAINS.find((chain) => chain.id === networkId);
  }, [networkId]);

  const networkName = chainParams?.name;
  const networkExplorer = chainParams?.explorer;

  return { networkName, networkExplorer };
};
