import { styled } from '@mui/material';
import { Box } from '@mui/system';

export const UiGridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto minmax(0px, 1fr)',
  gridRowGap: '0.4rem',
  gridColumnGap: '2rem',

  [`${theme.breakpoints.down('md')}`]: {
    display: 'flex',
    flexDirection: 'column',
    gridRowGap: '1.2rem',
  },
}));

export const UiGridContainerNoWrap = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto minmax(0px, 1fr)',
  gridRowGap: '0.4rem',
  gridColumnGap: '2rem',
}));

export const UiGridItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.5rem 0',
  flexWrap: 'wrap',

  [`${theme.breakpoints.down('md')}`]: {
    padding: 0,
  },
}));
