import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type NextPageProps = { [key: string]: string | number | null };

export const usePaginationWithSearchParams = (nextPageParams: NextPageProps | null) => {
  const [query, setQuery] = useSearchParams();

  const [page, setPage] = useState(1);
  const [paginationCount, setPaginationCount] = useState(0);
  const [prevPages, setPrevPages] = useState<NextPageProps[]>([]);

  const items_count = 50;

  const queryArgs = useMemo(() => {
    const params = new URLSearchParams(query);
    params.delete('page');
    params.delete('tab');
    return query.get('page') === '1' ? '' : params.toString();
  }, [query, page]);

  const queryParams = useMemo(() => {
    const params = {} as NextPageProps;

    Object.keys(nextPageParams || prevPages[prevPages.length - 1] || {}).forEach((param) => {
      const queryParam = query.get(param);
      params[param] = queryParam ? parseInt(queryParam) : 0;
    });

    const queryPageNumber = query.get('page');
    const page_number = queryPageNumber ? parseInt(queryPageNumber) : 1;

    return { params, page_number };
  }, [query, nextPageParams]);

  useEffect(() => {
    const { page_number } = queryParams;

    setPage(page_number);
  }, []);

  useEffect(() => {
    if (!nextPageParams) {
      setPaginationCount(queryParams.page_number);
      return;
    }

    const nextPageCount = nextPageParams.items_count
      ? parseInt(String(nextPageParams.items_count))
      : items_count;

    setPaginationCount(Math.round((nextPageCount + items_count) / items_count));
  }, [nextPageParams]);

  useEffect(() => {
    const { page_number } = queryParams;

    setPage(page_number);
  }, [queryParams]);

  function handlePageChange(pageNumber: number) {
    if (pageNumber === 1) {
      const tabQuery = query.get('tab');
      setQuery(
        tabQuery
          ? {
              tab: tabQuery,
            }
          : {},
      );
      setPrevPages([]);
      return;
    }

    if (pageNumber < page) {
      if (prevPages.length === 0) return;

      const prevPage = prevPages[prevPages.length - 1];

      Object.keys(queryParams.params).forEach((param) => {
        query.set(param, String(prevPage[param]));
      });
      query.set('page', String(pageNumber));
      setQuery(query);

      setPrevPages((prev) => {
        prev.pop();
        return prev;
      });
      return;
    }

    if (!nextPageParams) return;

    setPrevPages((prev) => [...prev, queryParams.params]);

    setQuery((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        page: String(pageNumber),
        ...nextPageParams,
      });
    });
  }

  return { handlePageChange, page, paginationCount, prevPages, queryArgs, items_count };
};
