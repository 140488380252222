import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
import { NftImage } from 'src/components/NftImage';
import { PageTitle } from 'src/components/PageTitle';
import { Metadata } from 'src/components/tokenDetails/Metadata';
import { TokensTransfers } from 'src/components/TokensTransfers';
import { UiGridContainerNoWrap, UiGridItem } from 'src/components/ui/UiGrid';
import { UiInfo } from 'src/components/ui/UiInfo';
import { UiInfoTooltip } from 'src/components/ui/UiInfoTooltip';
import { UiLink } from 'src/components/ui/UiLink';
import { UiTabs } from 'src/components/ui/UiTabs';
import { UiHash } from 'src/components/UiAddress';

import { useFetch } from 'src/hooks/useFetch';

import useResponsive from 'src/hooks/useResponsive';
import { ApiAddressInfoResp, ApiTokenInstance } from 'src/types/MultidataApi';
import { spaceBetweenCell } from 'src/utils/cssStyles';

export const TokenInstance = () => {
  const [tab, setTab] = useState('');
  const { address, tokenId } = useParams();
  const {
    fetchData,
    isLoading,
    txStatus,
    data: tokenInstance,
  } = useFetch<ApiTokenInstance>('failed to get token instance: ');

  const {
    fetchData: fetchAddressInfo,
    isLoading: isAddressInfoLoading,
    txStatus: addressInfoStatus,
    data: addressInfo,
  } = useFetch<ApiAddressInfoResp>('failed to get address info: ');

  const TABS = useMemo(() => {
    if (!tokenInstance) return;

    return [
      {
        value: 'transfers',
        label: 'Token transfers',
        component: (
          <Box sx={{ marginTop: '2rem' }}>
            <TokensTransfers
              fetchTransfers={() =>
                api.multidata.getTransfersInstance(address as string, tokenId as string)
              }
              isNft={
                tokenInstance.token.type === 'ERC-721' || tokenInstance.token.type === 'ERC-1155'
              }
            />
          </Box>
        ),
      },
      {
        value: 'metadata',
        label: 'Metadata',
        component: (
          <Box sx={{ marginTop: '2rem' }}>
            <Metadata tokenInstance={tokenInstance} />
          </Box>
        ),
      },
    ];
  }, [tokenInstance]);

  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    if (!address || !tokenId) return;
    fetchData(api.multidata.getTokenInstance(address, tokenId));
    fetchAddressInfo(api.multidata.getAddressInfo(address));
  }, [address, tokenId]);

  if (isLoading || isAddressInfoLoading) return <p>Loading...</p>;

  if (txStatus.error) return <p>{txStatus.error}</p>;

  if (!tokenInstance) return null;

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 2 }}>
        <PageTitle title={`${tokenInstance.token.name} #${tokenId}`} />
        <Box sx={{ marginLeft: '0.75rem' }}>
          <UiInfo>{tokenInstance.token.type}</UiInfo>
        </Box>
      </Box>
      <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
        {address && (
          <Typography variant={'subtitle1'}>
            <UiHash
              variant="subtitle1"
              hash={address}
              cutting={isMobile}
              cuttingOptions={[21, 4]}
              isContract
              withCopy
            />
          </Typography>
        )}
      </Stack>

      <Stack spacing={2}>
        {tokenInstance.image_url && <NftImage imgUrl={tokenInstance.image_url} maxWidth="28rem" />}

        <UiGridContainerNoWrap>
          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="Token name" />
            <Typography>Token</Typography>
          </UiGridItem>
          <UiGridItem>
            <UiLink to={`/${CONFIG.routes.tokenPage}/${address}`}>
              <Typography>{tokenInstance.token.name}</Typography>
            </UiLink>
          </UiGridItem>
          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="Current owner of this token instance" />
            <Typography>Owner</Typography>
          </UiGridItem>
          <UiGridItem>
            <UiHash
              hash={tokenInstance.owner.hash}
              cutting
              withCopy
              isContract={tokenInstance.owner.is_contract}
            />
          </UiGridItem>
          {addressInfo && addressInfo.creator_address_hash && (
            <>
              <UiGridItem sx={spaceBetweenCell}>
                <UiInfoTooltip title="Address that deployed this token contract" />
                <Typography>Creator</Typography>
              </UiGridItem>
              <UiGridItem>
                <UiHash hash={addressInfo.creator_address_hash} cutting withCopy />
              </UiGridItem>
            </>
          )}
          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="This token instance unique token ID" />
            <Typography>Token ID</Typography>
          </UiGridItem>
          <UiGridItem>
            <Typography>{tokenId}</Typography>
          </UiGridItem>

          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="Type of token" />
            <Typography>Type</Typography>
          </UiGridItem>
          <UiGridItem>
            <Typography>{tokenInstance.token.type}</Typography>
          </UiGridItem>
        </UiGridContainerNoWrap>
      </Stack>

      {TABS && (
        <Box mt="2rem">
          <UiTabs tab={tab} setTab={setTab} tabs={TABS} />
          {TABS.map((el) => el.value === tab && <Box key={el.value}>{el.component}</Box>)}
        </Box>
      )}
    </Box>
  );
};
