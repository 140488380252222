import { CONFIG } from 'src/app.config';
import {
  MetricValues,
  MetricValuesSourcePricesDep,
  MulV1Feed,
  MulV1FeedMetrics,
  MulV1Helper,
  MulV1HistoryPrices,
  MulV1LinkedTx,
  MulV1MetricFeed,
  MulV1Validator,
} from 'src/types/MultidataV1Api';
import { jsonFetch } from 'src/utils/jsonFetch';

async function getContractsChainsMap(): Promise<MulV1Helper> {
  return jsonFetch(`${CONFIG.service.apiV1}/helper/`);
}

async function getValidators(queryString?: string): Promise<MulV1Validator[]> {
  return jsonFetch(`${CONFIG.service.apiV1}/validator${queryString || ''}/`);
}

async function getFeeds(): Promise<MulV1Feed[]> {
  return jsonFetch(`${CONFIG.service.apiV1}/feeds/`);
}

async function getFeedsMetrics(
  chainName: string,
  feedsAddress: string,
): Promise<MulV1FeedMetrics[]> {
  return jsonFetch(`${CONFIG.service.apiV1}/chain/${chainName}/feeds/${feedsAddress}/metric/`);
}

async function getChainFeedsMetricValues(
  chainName: string,
  feedsAddress: string,
  metricAddress: string,
): Promise<MetricValues[]> {
  return jsonFetch(
    `${CONFIG.service.apiV1}/chain/${chainName}/feeds/${feedsAddress}/metric/${metricAddress}/values/`,
  );
}

async function getMetricValidatorDetailedValue(
  chainName: string,
  feedsAddress: string,
  metricAddress: string,
  validatorAddress: string,
  queryString?: string,
): Promise<MetricValuesSourcePricesDep> {
  return jsonFetch(
    `${
      CONFIG.service.apiV1
    }/chain/${chainName}/feeds/${feedsAddress}/metric/${metricAddress}/validator/${validatorAddress}/detailed_value/${
      queryString || ''
    }`,
  );
}

async function getFeedsForMetric(metricAddress: string): Promise<MulV1MetricFeed[]> {
  return jsonFetch(`${CONFIG.service.apiV1}/metric/${metricAddress}/`);
}

async function getChainFeedsLinkedTx(
  chainName: string,
  feedsAddress: string,
  txAddress: string,
): Promise<MulV1LinkedTx> {
  return jsonFetch(
    `${CONFIG.service.apiV1}/chain/${chainName}/feeds/${feedsAddress}/get_update_transaction/?helper_transaction_hash=${txAddress}`,
  );
}

async function getHistoryPricesForMetric(
  chainName: string,
  oracleAddress: string,
  metricAddress: string,
): Promise<MulV1HistoryPrices[]> {
  return jsonFetch(
    `${CONFIG.service.apiV1}/chain/${chainName}/feeds/${oracleAddress}/metric/${metricAddress}/history`,
  );
}

async function getHistoryPricesForValidator(
  chainName: string,
  oracleAddress: string,
  metricAddress: string,
  validatorAddress: string,
): Promise<MulV1HistoryPrices[]> {
  return jsonFetch(
    `${CONFIG.service.apiV1}/chain/${chainName}/feeds/${oracleAddress}/metric/${metricAddress}/validator/${validatorAddress}/history`,
  );
}

export const multidataV1 = {
  getContractsChainsMap,
  getValidators,
  getFeeds,
  getFeedsMetrics,
  getChainFeedsMetricValues,
  getMetricValidatorDetailedValue,
  getFeedsForMetric,
  getChainFeedsLinkedTx,
  getHistoryPricesForMetric,
  getHistoryPricesForValidator,
};
