import { Grid, MenuItem, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { RHFSelect } from 'src/components/form/RHFSelect';

export const EVMversionField = ({
  versions,
  disabled,
}: {
  versions?: string[];
  disabled?: boolean;
}) => {
  const { setValue, watch } = useFormContext();
  const values = watch();

  const handleSelectVersion = useCallback(
    (name: string) => {
      setValue('details.evmVersion', versions?.find((version) => version === name) || '');
    },
    [setValue, values.items],
  );

  const defaultValue = 'default';

  useEffect(() => {
    if (versions?.includes(defaultValue)) {
      setValue('details.evmVersion', defaultValue);
    }
  }, []);

  return (
    <>
      <Grid item xs={12} md={8}>
        <RHFSelect
          name="details.evmVersion"
          label="EVM Version*"
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
        >
          {versions?.map((version) => (
            <MenuItem key={version} value={version} onClick={() => handleSelectVersion(version)}>
              {version}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
          }}
        >
          The EVM version the contract is written for. If the bytecode does not match the version,
          we try to verify using the latest EVM version.{' '}
          <a href={'https://www.poa.network/'} target="_blank" rel="noopener noreferrer">
            EVM version details
          </a>
        </Typography>
      </Grid>
    </>
  );
};
