import { BigNumber } from 'bignumber.js';
import { Q112 } from 'src/constants/staticValues';
import { BN } from 'src/utils/bignumber';

function getAtomic(amount: BigNumber.Value, decimals = 18): string {
  return BN(amount).pow(BN(10).pow(decimals)).toFixed(0);
}

function getDisplay(amount: BigNumber.Value, decimals = 18, round = 4, cut = true): string {
  const getFirstTwoNonZeroValues = /^-?\d*\.?0*\d0*\d/;
  const findAllAfterDot = /\.([^.]+)$/;
  const res = BN(amount).div(BN(10).pow(decimals));
  if (!cut) return res.toString();
  if (BN(res).lt(0.01)) return (res.toString().match(getFirstTwoNonZeroValues) || ['0'])[0];
  if (BN(res.toString().match(findAllAfterDot)?.[0] || 0).eq(0)) return res.toFixed(0);
  if (res.toString().includes('.')) {
    return res.toString().substring(0, res.toString().indexOf('.') + round + 1);
  }
  return res.toFixed(round);
}

function numberWithSpaces(x: string) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

function getDisplayFromQ112(amount: BigNumber.Value, decimals = 18, round = 4): string {
  const amountFromQ112 = BN(amount).div(Q112);
  return getDisplay(amountFromQ112, decimals, round);
}

function getShortEntry(amount: BigNumber.Value): string {
  if (BN(amount).gte(1_000_000)) {
    return `${BN(amount).div(1_000_000).toFixed(2)}M`;
  }
  if (BN(amount).gte(1_000)) {
    return `${BN(amount).div(1_000).toFixed(2)}K`;
  }
  if (BN(amount).gte(1)) {
    return BN(amount).toFixed(2);
  }

  const y = amount.toString();
  const dotIndex = y.indexOf('.');
  if (dotIndex !== -1) {
    const a = y.substr(0, y.indexOf('.'));
    const b = y.substr(y.indexOf('.'));
    return a.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + b;
  }
  return y.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatNumber = {
  getAtomic,
  getDisplay,
  getShortEntry,
  getDisplayFromQ112,
};
