import { useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { useFetch } from 'src/hooks/useFetch';
import { ApiVerifiedContactsCountersResp, ApiVerifiedContractsResp } from 'src/types/MultidataApi';
import { api } from 'src/api';
import { UiHash } from 'src/components/UiAddress';
import { timeSince } from 'src/utils/time';
import { Iconify } from 'src/components/Iconify';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';
import { PageTitle } from 'src/components/PageTitle';
import { Filters } from 'src/components/Filters';
import { usePaginationWithSearchParams } from 'src/hooks/usePaginationWithSearchParams';
import { UiPagination } from 'src/components/ui/UiPagination';

const TABLE_HEAD_LABELS = [
  { id: 'contract', label: 'Contract' },
  { id: 'settings', label: 'Settings' },
  { id: 'verified', label: 'Verified' },
  { id: 'compiler', label: 'Compiler / version' },
  { id: 'txs', label: 'Txs' },
  { id: 'balance', label: 'Balance CHUNK' },
];

const filterSelect = {
  name: 'filter',
  defaultValue: 'all',
  items: [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'solidity',
      label: 'Solidity',
    },
    {
      value: 'vyper',
      label: 'Vyper',
    },
  ],
};

const filterString = {
  name: 'q',
  plaseholder: 'Search by contract name or address',
};

export const VerifiedContracts = () => {
  const { fetchData: fetchCounters, data: counters } = useFetch<ApiVerifiedContactsCountersResp>();
  const { fetchData, isLoading, txStatus, data } = useFetch<ApiVerifiedContractsResp>();

  const { handlePageChange, page, paginationCount, prevPages, queryArgs } =
    usePaginationWithSearchParams(data?.next_page_params || null);

  useEffect(() => {
    fetchData(api.multidata.getVerifiedContracts(queryArgs ? `?${queryArgs}` : ''));
  }, [queryArgs]);

  const decimals = 8;

  useEffect(() => {
    fetchCounters(api.multidata.getVerifiedContractsCounters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (txStatus.error) return <p>{txStatus.error}</p>;

  return (
    <>
      <PageTitle title="Verified Contracts" path="/" tooltip="Back to main page" mb={2} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CountersCard
            title="Total contracts"
            contractsAmount={counters?.smart_contracts}
            newContracts={counters?.new_smart_contracts_24h}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CountersCard
            title="Verified contracts"
            contractsAmount={counters?.verified_smart_contracts}
            newContracts={counters?.new_verified_smart_contracts_24h}
          />
        </Grid>
      </Grid>

      {
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          alignItems="flex-end"
          justifyContent="space-between"
          mt={6}
          mb={3}
        >
          <Filters filterSelect={filterSelect} filterString={filterString} />

          <UiPagination
            page={page}
            paginationCount={paginationCount}
            handlePageChange={handlePageChange}
            disabled={isLoading}
            emptyPrevPages={prevPages.length === 0}
          />
        </Stack>
      }

      {data && data.items.length > 0 ? (
        <TableContainer
          sx={{
            '& th:first-of-type, td:first-of-type': {
              paddingLeft: 3,
            },
          }}
        >
          <Table stickyHeader sx={{ overflowX: 'scroll' }}>
            <TableHeadCustom headLabel={TABLE_HEAD_LABELS} />
            {isLoading ? (
              <TableBody>
                <tr>
                  <td colSpan={TABLE_HEAD_LABELS.length}>
                    <p>Loading...</p>
                  </td>
                </tr>
              </TableBody>
            ) : (
              <TableBody>
                {data?.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ whiteSpace: 'nowrap', width: '1rem' }}>
                      <UiHash
                        hash={item.address.hash}
                        isContract={item.address.is_contract}
                        name={item.address.name}
                      />
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <StatusIcon
                          enabled={item.optimization_enabled}
                          tooltipTitle="Optimization"
                        />
                        <StatusIcon
                          enabled={item.has_constructor_args}
                          tooltipTitle="Constructor args"
                        />
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Iconify
                          icon={'clarity:success-standard-solid'}
                          color={(theme) => theme.palette.success.main}
                        />
                        <Typography variant="body2">
                          {timeSince(Number(new Date(item.verified_at)))} ago
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell width="1rem">
                      <Typography variant="body2" whiteSpace="nowrap">
                        <b style={{ textTransform: 'capitalize' }}>{item.language}</b>{' '}
                        {item.compiler_version}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.tx_count || '0'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {numberWithDividers(
                          formatNumber.getDisplay(item.coin_balance, decimals, 4),
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Typography>There are no contracts.</Typography>
      )}
    </>
  );
};

const StatusIcon = ({ enabled, tooltipTitle }: { enabled: boolean; tooltipTitle: string }) => (
  <Tooltip placement="top" title={tooltipTitle}>
    <Iconify
      icon={enabled ? 'ph:check-bold' : 'ph:x-bold'}
      color={(theme) => (enabled ? theme.palette.success.main : theme.palette.error.main)}
      sx={{ cursor: 'pointer' }}
    />
  </Tooltip>
);

const CountersCard = ({
  title,
  contractsAmount,
  newContracts,
}: {
  title: string;
  contractsAmount?: string;
  newContracts?: string;
}) => (
  <Card sx={{ boxShadow: 'none', background: (theme) => theme.palette.grey[200] }}>
    <CardContent>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" pr="1rem">
          {numberWithDividers(contractsAmount || '0')}
        </Typography>
        {newContracts && parseInt(newContracts) > 0 && (
          <>
            <Typography variant="h5" color={(theme) => theme.palette.primary.main} pr="0.25rem">
              + {numberWithDividers(newContracts)}
            </Typography>
            <Typography color={(theme) => theme.palette.text.secondary}>(24h)</Typography>
          </>
        )}
      </Box>
      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>
    </CardContent>
  </Card>
);
