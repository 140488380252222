import { Box, Card, CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import { api } from 'src/api';
import Chart, { useChart } from 'src/components/chart';
import { useFetch } from 'src/hooks/useFetch';
import { ApiStatsChartResp } from 'src/types/MultidataApi';
import { numberWithCommas } from 'src/utils/misc';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const TransactionsChart = () => {
  const theme = useTheme();
  const { fetchData, txStatus, data } = useFetch<ApiStatsChartResp>(
    'failed to get stats information: ',
  );

  const chartData = useMemo(() => data?.chart_data.reverse(), [data]);

  const chartOptions = useChart({
    colors: [theme.palette.text.primary],
    xaxis: {
      tickAmount: 4,
      categories: chartData?.map((el, i) => {
        const date = new Date(el.date);
        return `${date.getDate()} ${monthNames[date.getMonth()]}`;
      }),
      labels: {
        rotate: 0,
      },
    },
    stroke: {
      width: 2,
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        formatter: numberWithCommas,
      },
    },
  });

  useEffect(() => {
    fetchData(api.multidata.getTxsChart());
  }, []);

  const totalTransactions = useMemo(() => {
    return chartData?.reduce((acc, val) => acc + val.tx_count, 0);
  }, [chartData]);

  if (!chartData) return null;

  return (
    <Card style={{ height: '100%', boxShadow: 'none' }}>
      <CardHeader
        title={`Daily transactions (last ${chartData.length} days)`}
        subheader={numberWithCommas(Number(totalTransactions))}
        sx={{ pl: 2 }}
      />

      <Box sx={{ mt: 3, height: '366px' }} dir="ltr">
        {txStatus.error ? (
          <Typography variant={'h4'}>Problem with loading chart data</Typography>
        ) : (
          <Chart
            height="94%"
            type="line"
            options={chartOptions}
            series={[{ name: 'Tx/day', data: chartData.map((el) => el.tx_count) }]}
          />
        )}
      </Box>
    </Card>
  );
};
