import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CopyIcon } from 'src/components/CopyIcon';
import { IconCode } from 'src/components/Icons';
import { UiCodeBlock } from 'src/components/ui/UiCodeBlock';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { ApiContractInfo } from 'src/types/MultidataApi';

export const CommonCode = ({
  contractInfo,
  verifyButton,
}: {
  contractInfo: ApiContractInfo;
  verifyButton: EmotionJSX.Element;
}) => {
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  function handleCopyCreationCode(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    copy(String(JSON.stringify(contractInfo?.creation_bytecode)));
    enqueueSnackbar('Contract creation code copied!');
  }

  function handleCopyDeployedCode(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    copy(String(JSON.stringify(contractInfo?.deployed_bytecode)));
    enqueueSnackbar('Contract deployed code copied!');
  }

  return (
    <Box>
      <Box mb={6}>
        <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <IconCode mr={1} />
            <Typography variant="subtitle1">Contract creation code</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            {!contractInfo.is_verified && (
              <Stack direction="row" alignItems="center">
                {!contractInfo.is_verified && verifyButton}
              </Stack>
            )}
            <Tooltip title="Copy creation code to clipboard">
              <Box>
                <CopyIcon onCopy={handleCopyCreationCode} />
              </Box>
            </Tooltip>
          </Stack>
        </Stack>
        <UiCodeBlock code={contractInfo.creation_bytecode} />
      </Box>
      <Box mb={6}>
        <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <IconCode mr={1} />
            <Typography variant="subtitle1">Deployed ByteCode</Typography>
          </Stack>
          <Tooltip title="Copy deployed code to clipboard">
            <Box>
              <CopyIcon onCopy={handleCopyDeployedCode} />
            </Box>
          </Tooltip>
        </Stack>
        <UiCodeBlock code={contractInfo.deployed_bytecode} />
      </Box>
    </Box>
  );
};
