import { SxProps, Tab, Tabs, TabsProps, Theme } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type TabsType<T> = readonly {
  value: T;
  label: string | React.ReactNode;
  disabled?: boolean;
  component?: React.ReactNode;
}[];

type Props<T> = {
  tab: T;
  setTab: (v: T) => void;
  tabs: TabsType<T>;
  disabled?: boolean;
  tabSx?: SxProps<Theme>;
  withSearchParams?: boolean;
};

export function UiTabs<T = string>({
  tab,
  setTab,
  tabs,
  withSearchParams = true,
  disabled,
  tabSx,
  ...props
}: Props<T> & TabsProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const filteredTabs = useMemo(() => tabs.filter((el) => !el.disabled), [tabs]);

  const getInitTab = useCallback(() => {
    const initTabQuery = searchParams.get('tab');
    return filteredTabs.find((tab) => tab.value === initTabQuery)?.value || filteredTabs[0].value;
  }, [filteredTabs, searchParams]);

  useEffect(() => {
    if (filteredTabs.length === 0) return;

    setTab(getInitTab());
  }, [filteredTabs, getInitTab, setTab]);

  if (!tab) return null;

  return (
    <Tabs
      value={tab}
      onChange={(_, val) => {
        setTab(val);
        if (withSearchParams) {
          setSearchParams({ tab: val });
        }
      }}
      selectionFollowsFocus
      {...props}
    >
      {filteredTabs.map((el) => (
        <Tab
          key={el.value as string}
          value={el.value}
          label={el.label}
          disabled={disabled}
          sx={tabSx}
        />
      ))}
    </Tabs>
  );
}
