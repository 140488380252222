import { Box } from '@mui/material';
import { ElementType, useEffect, useRef, useState } from 'react';
import { FCC } from 'src/types/FCC';

type FadeProps = {
  component?: ElementType;
};

export const Fade: FCC<FadeProps> = ({ children, component: Component }) => {
  const [animate, setAnimate] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => setAnimate(true), 1);

    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Box
      sx={{
        opacity: animate ? 1 : 0,
        transform: animate ? 'scale(1)' : 'scale(0.85)',
        transition: 'opacity 0.4s ease-in-out, transform 0.7s ease',
      }}
      component={Component}
    >
      {children}
    </Box>
  );
};
