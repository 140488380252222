import React, { useRef, useState } from 'react';
import { remoteData, RemoteData } from 'src/utils/remoteData';

type UseRemoteDataApi<SuccessData, InitialData = null, Err = any> = {
  getState: () => RemoteData<SuccessData, InitialData, Err>;
  setState: React.Dispatch<React.SetStateAction<RemoteData<SuccessData, InitialData, Err>>>;
  setNotAsked: (data?: InitialData) => void;
  setLoading: (data?: InitialData) => void;
  setSuccess: (data: SuccessData) => void;
  setFailure: (error: Err, data?: InitialData) => void;
  track: (promise: Promise<SuccessData>) => Promise<void>;
};

type UseRemoteDataReturn<SuccessData, InitialData = null, Err = any> = [
  remoteData: RemoteData<SuccessData, InitialData, Err>,
  api: UseRemoteDataApi<SuccessData, InitialData, Err>,
];

/**
 * A hook that bounds RemoteData state and its updates to React state.
 * @param initialState - initial state of the RemoteData
 * @param log - a function to log changes of the RemoteData state.
 * @returns a tuple consisting of the RemoteData itself and the API object,
 * which is represented by the set of methods to control the RemoteData state.
 * The API object is immutable - it is always the same object in memory.
 */
export const useRemoteData = <SuccessData, InitialData = null, Err = any>(
  initialState: InitialData,
  log?: (...args: any[]) => void,
): UseRemoteDataReturn<SuccessData, InitialData, Err> => {
  const [state, setState] = useState<RemoteData<SuccessData, InitialData, Err>>(
    remoteData.makeNotAsked(initialState),
  );

  const getState = () => state;

  const setNotAsked = (newState = initialState) => {
    log?.('RD notAsked', newState);
    setState(remoteData.makeNotAsked(newState));
  };

  const setLoading = (newState = initialState) => {
    log?.('RD loading', newState);
    setState(remoteData.makeLoading(newState));
  };

  const setSuccess = (newState: SuccessData) => {
    log?.('RD success', newState);
    setState(remoteData.makeSuccess(newState));
  };

  const setFailure = (err: Err, newState = initialState) => {
    log?.('RD failure', err, newState);
    setState(remoteData.makeFailure(err, newState));
  };

  const track = (promise: Promise<SuccessData>) => {
    setLoading();
    return promise.then(setSuccess).catch(setFailure);
  };

  const api = {
    getState,
    setState,
    setNotAsked,
    setLoading,
    setSuccess,
    setFailure,
    track,
  } as const;
  const apiRef = useRef(api);
  // meld new api methods into existing object, without changing the link on it
  Object.assign(apiRef.current, api);

  return [state, apiRef.current];
};
