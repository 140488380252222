import { createContext, useContext, useEffect } from 'react';
import { api } from 'src/api';
import { CHAINS } from 'src/constants/chains';
import { useFetch } from 'src/hooks/useFetch';
import { FCC } from 'src/types/FCC';

interface ContractsChainsMapCtxProps {
  contractChainMap: Record<string, string> | undefined;
}

export const ContractChainMapCtx = createContext<ContractsChainsMapCtxProps>({
  contractChainMap: {},
});

export const ContractChainMapProvider: FCC = ({ children }) => {
  const { fetchData, data } = useFetch<Record<string, string>>("Can't fetch helpers");

  useEffect(() => {
    fetchData(api.multidataV1.getContractsChainsMap());
  }, []);

  return (
    <ContractChainMapCtx.Provider value={{ contractChainMap: data }}>
      {children}
    </ContractChainMapCtx.Provider>
  );
};

export const useContractChainMap = () => {
  const { contractChainMap } = useContext(ContractChainMapCtx);

  function getWriteChain(hash: string | undefined) {
    if (!hash) return;
    return (
      CHAINS.find((chain) => chain.id === contractChainMap?.[hash])?.name ||
      contractChainMap?.[hash]
    );
  }

  function getWriteChainId(hash: string | undefined) {
    if (!hash) return;
    return contractChainMap?.[hash];
  }

  return {
    contractChainMap,
    getWriteChain,
    getWriteChainId,
  };
};
