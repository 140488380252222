import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Grid, MenuItem, Typography } from '@mui/material';

import { UiInfoTooltip } from 'src/components/ui/UiInfoTooltip';
import { RHFSelect } from 'src/components/form/RHFSelect';
import { VerificationMethodType } from 'src/types/MultidataApi';

const verificationMethods: { id: VerificationMethodType; name: string }[] = [
  {
    id: 'flattened-code',
    name: 'Solidity (Flattened source code)',
  },
  {
    id: 'standard-input',
    name: 'Solidity (Standard JSON input)',
  },
  {
    id: 'multi-part',
    name: 'Solidity (Multi-part files)',
  },
  {
    id: 'vyper-code',
    name: 'Vyper (Contract)',
  },
  {
    id: 'vyper-multi-part',
    name: 'Vyper (Multi-part files)',
  },
];

export const VerificationMethodsField = ({ disabled }: { disabled?: boolean }) => {
  const { setValue, reset } = useFormContext();

  const handleSelectService = useCallback(
    (methodId: string) => {
      reset();
      setValue(
        'verificationMethod',
        verificationMethods.find((service) => service.id === methodId)?.id,
      );
    },
    [reset, setValue],
  );

  // TODO: add dinamic name from config
  const explorerName = 'Explorer';

  return (
    <>
      <Grid item xs={12}>
        <Box color={(theme) => theme.palette.text.disabled}>
          <Typography variant="h6" display={'inline'}>
            Currently, {explorerName} supports 5 contract verification methods
          </Typography>
          <UiInfoTooltip
            style={{
              flexShrink: 0,
              display: 'inline',
              verticalAlign: 'text-bottom',
              marginLeft: '0.5rem',
            }}
            title={
              <>
                Currently, {explorerName} supports 5 methods:
                <ol>
                  <li>Verification through flattened source code.</li>
                  <li>Verification using Standard input JSON file.</li>
                  <li>Verification of multi-part Solidity files.</li>
                  <li>Verification of Vyper contract.</li>
                  <li>Verification of multi-part Vyper files.</li>
                </ol>
              </>
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <RHFSelect
          name="verificationMethod"
          label="Verification method (compiler type)"
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
        >
          {verificationMethods?.map((method) => (
            <MenuItem
              key={method.id}
              value={method.id}
              onClick={() => handleSelectService(method.id)}
            >
              {method.name}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>
    </>
  );
};
