import { createContext, useState } from 'react';
import { FCC } from 'src/types/FCC';

interface SettingsCtxProps {
  navMini?: boolean;
  setNavMini: (v: boolean) => void;
}

export const SettingsCtx = createContext<SettingsCtxProps>({
  navMini: false,
  setNavMini: () => {},
});

export const SettingsCtxProvider: FCC = ({ children }) => {
  const [navMini, setNavMini] = useState(false);

  return <SettingsCtx.Provider value={{ navMini, setNavMini }}>{children}</SettingsCtx.Provider>;
};
