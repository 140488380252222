import { Card, Typography, Box, CardProps } from '@mui/material';

interface Props extends CardProps {
  title: string;
  total: string;
  icon: React.ReactElement;
}

export default function StatisticCard({ title, total, icon, sx, ...other }: Props) {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        p: 2,
        pl: 3,
        boxShadow: 'none',
        backgroundColor: (theme) => theme.palette.grey[200],
        border: 'none',
        ...sx,
      }}
      {...other}
    >
      <div>
        <Typography variant="h3">{total}</Typography>

        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
      </div>
    </Card>
  );
}
