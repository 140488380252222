import { useContext, useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { api } from 'src/api';
import { NetworkIconsList } from 'src/components/NetworkIconsList';
import { Scrollbar } from 'src/components/scrollbar/Scrollbar';
import { CHAINS } from 'src/constants/chains';
import { SCROLL_BAR_MAX_SIZE } from 'src/constants/ui';
import { useFetch } from 'src/hooks/useFetch';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { ChainName } from 'src/types/Asset';
import { MulV1MetricFeed } from 'src/types/MultidataV1Api';

export const SelectNetwork = () => {
  const { networkId, address } = useParams();
  const { feeds } = useContext(AssetsCtx);

  const {
    fetchData: fetchFeeds,
    data: availableFeeds,
    isLoading,
  } = useFetch<MulV1MetricFeed[]>('failed to get feeds by asset: ');

  useEffect(() => {
    if (!address) return;

    fetchFeeds(api.multidataV1.getFeedsForMetric(address));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const networksForAsset = useMemo(() => {
    if (isLoading) return [];
    return availableFeeds?.map((feed) => feed.name);
  }, [availableFeeds, isLoading]);

  return (
    <>
      {feeds && (
        <Scrollbar scrollbarMaxSize={SCROLL_BAR_MAX_SIZE}>
          <NetworkIconsList
            networks={CHAINS.filter((el) => feeds.map((feed) => feed.name).includes(el.id))}
            selectedId={networkId as ChainName}
            // networksForAsset={networksForAsset}
          />
        </Scrollbar>
      )}
      <Outlet />
    </>
  );
};
