import { FC } from 'react';
import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  useTheme,
} from '@mui/material';
import { Iconify } from '../Iconify';

export const UiAccordion: FC<AccordionProps> = ({ children, ...others }) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        padding: '0.1rem',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '2px',
      }}
      {...others}
    >
      {children}
    </Accordion>
  );
};

export const UiAccordionSummary: FC<AccordionSummaryProps> = ({ children, ...others }) => {
  const theme = useTheme();

  return (
    <AccordionSummary
      sx={{
        minHeight: '2.4rem',
        margin: '0',
        background: `${theme.palette.grey[200]}`,
        borderRadius: '2px',
        '& .MuiAccordionSummary-content,  & .MuiAccordionSummary-content.Mui-expanded': {
          margin: '0',
        },
        '&.Mui-expanded': {
          minHeight: '2.4rem',
          margin: '0',
        },
      }}
      expandIcon={<Iconify icon={'material-symbols:expand-more'} />}
      {...others}
    >
      {children}
    </AccordionSummary>
  );
};
