import { AppBar, IconButton, Stack, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Searchbar } from 'src/components/header/Searchbar';
import { Logo } from 'src/components/Logo';
import { NavHorizontal } from 'src/components/nav/NavHorizontal/NavHorizontal';
import NavVertical from 'src/components/nav/NavVertical';
import { HEADER } from 'src/config-global';
import useResponsive from 'src/hooks/useResponsive';
import { bgBlur } from 'src/utils/cssStyles';
import { Iconify } from '../Iconify';

export const Header = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);

  return (
    <>
      {!isDesktop && <NavVertical openNav={open} onCloseNav={() => setOpen(false)} />}
      <AppBar
        sx={{
          boxShadow: 'none',
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 2 },
          }}
        >
          <Stack
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {isDesktop && <Logo />}
            <Stack
              direction="row"
              flexGrow={1}
              justifyContent={{ sm: 'flex-start', lg: 'flex-end' }}
            >
              {isDesktop && <NavHorizontal />}
              <Searchbar />
            </Stack>
            {!isDesktop && (
              <IconButton onClick={() => setOpen(true)} sx={{ mr: 1, color: 'text.primary' }}>
                <Iconify icon="iconamoon:menu-burger-horizontal" width={24} />
              </IconButton>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};
