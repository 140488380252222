import { FC } from 'react';
import { Iconify } from 'src/components/Iconify';
import { Label } from 'src/components/label/Label';

export const StatusPoint: FC<{ error?: boolean }> = ({ error }) => (
  <>
    {error ? (
      <Label
        variant="soft"
        color="error"
        startIcon={
          <Iconify
            icon="material-symbols:error-rounded"
            color={(theme) => theme.palette.error.main}
          />
        }
      >
        Error
      </Label>
    ) : (
      <Label
        variant="soft"
        color="success"
        startIcon={
          <Iconify
            icon="clarity:success-standard-solid"
            color={(theme) => theme.palette.success.main}
          />
        }
      >
        Success
      </Label>
    )}
  </>
);
