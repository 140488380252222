import {
  FormControl,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Iconify } from 'src/components/Iconify';

type Props = {
  filterSelect: {
    name: string;
    defaultValue?: string;
    items: {
      value: string;
      label: string;
    }[];
    multiple?: boolean;
  };
  filterString: {
    name: string;
    plaseholder: string;
  };
};

export const Filters: FC<Props> = ({ filterSelect, filterString }) => {
  const [query, setQuery] = useSearchParams();

  const [select, setSelect] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const queryParams = useMemo(() => {
    const filter = query.get(filterSelect.name) || filterSelect.defaultValue || '';
    const q = query.get(filterString.name) || '';
    return { filter, q };
  }, [filterSelect, filterString.name, query]);

  useEffect(() => {
    const { filter, q } = queryParams;

    setSelect(filter.split(','));
    setSearch(q);
  }, [queryParams]);

  const onChangeSelect = (_: React.MouseEvent<HTMLElement>, value: string[]) => {
    if (
      (filterSelect.defaultValue && value.includes(filterSelect.defaultValue)) ||
      value.length === 0
    ) {
      query.delete(filterSelect.name);
      setQuery(query);
      return;
    }
    setQuery({
      [filterSelect.name]: [value].join(','),
      ...(search && {
        [filterString.name]: search,
      }),
    });
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery({
      ...((!filterSelect.defaultValue ||
        (filterSelect.defaultValue && !select.includes(filterSelect.defaultValue))) && {
        [filterSelect.name]: select,
      }),
      ...(e.target.value && {
        [filterString.name]: e.target.value,
      }),
    });
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      alignItems={{ xs: 'stretch', sm: 'baseline' }}
      width={'100%'}
    >
      <ToggleButtonGroup
        value={select}
        exclusive={!filterSelect.multiple}
        onChange={onChangeSelect}
        aria-label={filterSelect.name}
      >
        {filterSelect.items.map((el, i) => (
          <ToggleButton
            key={i}
            value={el.value}
            aria-label={el.label}
            sx={{
              minWidth: '5rem',
            }}
          >
            {el.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <FormControl sx={{ flexGrow: 1 }}>
        <TextField
          size="small"
          sx={{ width: { sm: '21rem' } }}
          variant="standard"
          label={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon={'tabler:search'} />
              <Typography>{filterString.plaseholder}</Typography>
            </Stack>
          }
          value={search}
          name={filterString.name}
          onChange={onChangeSearch}
          InputProps={{
            endAdornment: search && (
              <Iconify
                icon={'mdi:clear-circle'}
                color={(theme) => theme.palette.action.disabled}
                onClick={() => {
                  setQuery({
                    [filterSelect.name]: select,
                  });
                }}
                sx={{ cursor: 'pointer' }}
              />
            ),
            disableUnderline: true,
          }}
        />
      </FormControl>
    </Stack>
  );
};
