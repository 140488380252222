import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
import { LatestBlocksItem } from 'src/components/LatestBlocksItem';
import { LastItemsContainer } from 'src/components/pageHome/LastItemsContainer';
import { useFetch } from 'src/hooks/useFetch';
import { useNewBlock } from 'src/hooks/useNewBlock';
import { ApiBlock } from 'src/types/MultidataApi';
import { Fade } from 'src/components/Fade';

export const LastBlocks = () => {
  const { fetchData, data: blocks } = useFetch<ApiBlock[]>('failed to get stats information: ');
  const [renderBlocks, setRenderBlocks] = useState<ApiBlock[] | null>(null);
  const newBlock = useNewBlock();

  useEffect(() => {
    fetchData(api.multidata.getMainPageBlocks());
  }, []);

  useEffect(() => {
    if (!blocks) return;
    setRenderBlocks(blocks);
  }, [blocks]);

  useEffect(() => {
    if (!newBlock) return;

    setRenderBlocks((oldBlocks) => {
      if (!oldBlocks) return null;
      const slicedOldBlocks = oldBlocks.slice(0, -1);
      return [newBlock, ...slicedOldBlocks];
    });
  }, [newBlock]);

  if (!blocks || !renderBlocks) return null;

  return (
    <LastItemsContainer
      title="Latest blocks"
      link={{ to: CONFIG.routes.blocksPage, text: 'View all blocks' }}
    >
      {renderBlocks.map((block) => (
        <Grid key={block.height} item md={3} style={{ maxWidth: '100%' }}>
          <Fade>
            <LatestBlocksItem block={block} />
          </Fade>
        </Grid>
      ))}
    </LastItemsContainer>
  );
};
