import React from 'react';
import { ReactComponent as PolygonIcon } from 'src/assets/chains/polygon.svg';
import { ChainName, ChainProps } from 'src/types/Asset';
import { ReactComponent as ArbitrumIcon } from '../assets/chains/arbitrum.svg';
import { ReactComponent as AstarLogo } from '../assets/chains/astar.svg';
import { ReactComponent as AuroraIcon } from '../assets/chains/aurora.svg';
import { ReactComponent as AvalancheIcon } from '../assets/chains/avalanche.svg';
import { ReactComponent as BittorrentIcon } from '../assets/chains/bittorrent.svg';
import { ReactComponent as BnbIcon } from '../assets/chains/bnb.svg';
import { ReactComponent as BobaIcon } from '../assets/chains/boba.svg';
import { ReactComponent as CantoIcon } from '../assets/chains/canto.svg';
import { ReactComponent as CeloIcon } from '../assets/chains/celo.svg';
import { ReactComponent as CLVIcon } from '../assets/chains/clv.svg';
import { ReactComponent as CronosIcon } from '../assets/chains/cronos.svg';
import { ReactComponent as DogechainIcon } from '../assets/chains/dogechain.svg';
import { ReactComponent as EOSIcon } from '../assets/chains/eos.svg';
import { ReactComponent as EtcIcon } from '../assets/chains/etc.svg';
import { ReactComponent as FantomOperaIcon } from '../assets/chains/fantom-opera.svg';
import { ReactComponent as FusionLogo } from '../assets/chains/fusion.svg';
import { ReactComponent as GnosisIcon } from '../assets/chains/gnosis.svg';
import { ReactComponent as HarmonyIcon } from '../assets/chains/harmony.svg';
import { ReactComponent as HecoIcon } from '../assets/chains/heco-mainnet.svg';
import { ReactComponent as KlaytnIcon } from '../assets/chains/klaytn.svg';
import { ReactComponent as LineaIcon } from '../assets/chains/linea.svg';
import { ReactComponent as MantleLogo } from '../assets/chains/mantle.svg';
import { ReactComponent as MetisIcon } from '../assets/chains/metis.svg';
import { ReactComponent as MoonbeamIcon } from '../assets/chains/moonbeam.svg';
import { ReactComponent as MoonriverIcon } from '../assets/chains/moonriver.svg';
import { ReactComponent as OasisNetworkIcon } from '../assets/chains/oasis.svg';
import { ReactComponent as OkcIcon } from '../assets/chains/okc.svg';
import { ReactComponent as OptimismIcon } from '../assets/chains/optimism.svg';
import { ReactComponent as SyscoinIcon } from '../assets/chains/syscoin.svg';
import { ReactComponent as WemixLogo } from '../assets/chains/wemix.svg';
import { ReactComponent as MultidataLogo } from '../assets/images/multidata_logo.svg';

export const DEFAULT_CHAIN_ID: ChainName = 'arbitrum';

export const CHAINS = [
  {
    name: 'EOS',
    id: 'eos',
    icon: <EOSIcon />,
    explorer: 'https://explorer.evm.eosnetwork.com',
  },
  {
    name: 'Bsc',
    id: 'bsc',
    icon: <BnbIcon />,
    explorer: 'https://bscscan.com',
  },
  {
    name: 'Gnosis',
    id: 'xdai',
    icon: <GnosisIcon />,
    explorer: 'https://blockscout.com/xdai/mainnet',
  },
  {
    name: 'Polygon',
    id: 'polygon',
    icon: <PolygonIcon />,
    explorer: 'https://polygonscan.com',
  },
  {
    name: 'Optimism',
    id: 'optimism',
    icon: <OptimismIcon />,
    explorer: 'https://optimistic.etherscan.io',
  },
  {
    name: 'Fantom',
    id: 'fantom',
    icon: <FantomOperaIcon />,
    explorer: 'https://ftmscan.com',
  },
  {
    name: 'Fusion',
    id: 'fusion',
    icon: <FusionLogo />,
    explorer: 'https://fsnscan.com/',
  },
  {
    name: 'Mantle',
    id: 'mantle',
    icon: <MantleLogo />,
    explorer: 'https://explorer.mantle.xyz/',
  },
  {
    name: 'Metis',
    id: 'metis',
    icon: <MetisIcon />,
    explorer: 'https://explorer.metis.io/',
  },
  {
    name: 'Canto',
    id: 'canto',
    icon: <CantoIcon />,
    explorer: 'https://cantoscan.com/',
  },
  {
    name: 'Avalanche',
    id: 'avalanche',
    icon: <AvalancheIcon />,
    explorer: 'https://avascan.info/blockchain/stepnetwork',
  },
  {
    name: 'Harmony',
    id: 'harmony',
    icon: <HarmonyIcon />,
    explorer: 'https://explorer.harmony.one',
  },
  {
    name: 'Heco',
    id: 'heco',
    icon: <HecoIcon />,
    explorer: 'https://hecoinfo.com',
  },
  {
    name: 'Oasis',
    id: 'oasis',
    icon: <OasisNetworkIcon />,
    explorer: 'https://explorer.emerald.oasis.dev',
  },
  {
    name: 'Arbitrum',
    id: 'arbitrum',
    icon: <ArbitrumIcon />,
    explorer: 'https://arbiscan.io',
  },
  {
    name: 'Syscoin',
    id: 'syscoin',
    icon: <SyscoinIcon />,
    explorer: 'https://explorer.syscoin.org',
  },
  {
    name: 'Boba',
    id: 'boba',
    icon: <BobaIcon />,
    explorer: 'https://blockexplorer.boba.network',
  },
  {
    name: 'Okex',
    id: 'okex',
    icon: <OkcIcon />,
    explorer: 'https://www.oklink.com/oktc',
  },
  {
    name: 'X1',
    id: 'x1',
    icon: <OkcIcon />,
    explorer: 'https://www.oklink.com/xlayer-test',
  },
  {
    name: 'Moonriver',
    id: 'moonriver',
    icon: <MoonriverIcon />,
    explorer: 'https://moonriver.moonscan.io',
  },
  {
    name: 'Celo',
    id: 'celo',
    icon: <CeloIcon />,
    explorer: 'https://celoscan.io',
  },
  {
    name: 'Wemix',
    id: 'wemix',
    icon: <WemixLogo />,
    explorer: 'https://explorer.wemix.com/',
  },
  {
    name: 'Astar',
    id: 'astar',
    icon: <AstarLogo />,
    explorer: 'https://astar.subscan.io/',
  },
  {
    name: 'Moonbeam',
    id: 'moonbeam',
    icon: <MoonbeamIcon />,
    explorer: 'https://moonscan.io',
  },
  {
    name: 'Eth Classic',
    id: 'etc',
    icon: <EtcIcon />,
    explorer: 'https://blockscout.com/etc/mainnet',
  },
  {
    name: 'CLV Chain',
    id: 'clover',
    icon: <CLVIcon />,
    explorer: 'https://clvscan.com',
  },
  {
    name: 'Bittorrent',
    id: 'bittorrent',
    icon: <BittorrentIcon />,
    explorer: 'https://bttcscan.com',
  },
  {
    name: 'Cronos',
    id: 'cronos',
    icon: <CronosIcon />,
    explorer: 'https://cronoscan.com',
  },
  {
    name: 'Klaytn',
    id: 'klaytn',
    icon: <KlaytnIcon />,
    explorer: 'https://scope.klaytn.com',
  },
  {
    name: 'Aurora',
    id: 'aurora',
    icon: <AuroraIcon />,
    explorer: 'https://aurorascan.dev',
  },
  {
    name: 'Dogechain',
    id: 'dogechain',
    icon: <DogechainIcon />,
    explorer: 'https://explorer.dogechain.dog',
  },
  {
    name: 'Chunk',
    id: 'chunk',
    icon: <MultidataLogo />,
    explorer: window.location.origin,
  },
  {
    name: 'Linea',
    id: 'linea',
    explorer: 'https://explorer.goerli.linea.build',
    icon: <LineaIcon />,
  },
].sort((a, b) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
}) as ChainProps[];

export const SUPPORTED_NETWORKS = CHAINS.map((chain) => chain.id).filter(
  (item) => !(['syscoin', 'boba', 'aurora'] as ChainName[]).includes(item),
);
