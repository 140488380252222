import { Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ethers } from 'ethers';
import { FC, useEffect, useState } from 'react';
import { UiLink } from 'src/components/ui/UiLink';
import { SOURCES } from 'src/constants/sources';
import { Q112 } from 'src/constants/staticValues';
import erc20Abi from 'src/contracts/ERC20abi.json';
import { DepWithParams, MetricValuesSourcePricesDep } from 'src/types/MultidataV1Api';
import { BN } from 'src/utils/bignumber';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';

const NETWORK_EXPLORER = 'https://etherscan.io';

const StyledItem = styled(Grid)(({ theme }) => ({
  position: 'relative',
  borderLeft: '2px solid #ddd',
  '&:last-child': {
    borderColor: 'transparent',
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '-3px',
    left: '-2px',
    width: '10px',
    height: '20px',
    border: 'solid #ddd',
    borderWidth: '0 0 2px 2px',
  },
}));

export const ValidatorDep: FC<{
  dep: MetricValuesSourcePricesDep;
  alias?: MetricValuesSourcePricesDep;
  ethProvider: ethers.providers.JsonRpcProvider;
}> = ({ dep, alias, ethProvider }) => {
  const [assetSymbol, setAssetSymbol] = useState('');
  const [refAssetSymbol, setRefAssetSymbol] = useState('');

  useEffect(() => {
    if (!('params' in dep)) return;
    dep.params.asset
      ? new ethers.Contract(dep.params.asset, erc20Abi, ethProvider)
          .symbol()
          .then((resp: string) => setAssetSymbol(resp))
      : setAssetSymbol('');
    dep.params.ref_asset
      ? new ethers.Contract(dep.params.ref_asset, erc20Abi, ethProvider)
          .symbol()
          .then((resp: string) => setRefAssetSymbol(resp))
      : setRefAssetSymbol('');
  }, []);

  const getPair = (dep: DepWithParams) => {
    if (dep.params.pair) return <Typography>{dep.params.pair}</Typography>;

    return (
      <Stack direction="row" spacing={0.5} pr={2} width="max-content">
        {assetSymbol ? (
          <UiLink
            to={`${NETWORK_EXPLORER}/address/${dep.params.asset}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ cursor: 'pointer' }}
            onClick={(e) => e.stopPropagation()}
          >
            {assetSymbol}
          </UiLink>
        ) : (
          '...'
        )}
        <Typography component="span">/</Typography>
        {refAssetSymbol ? (
          <UiLink
            to={`${NETWORK_EXPLORER}/address/${dep.params.ref_asset}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ cursor: 'pointer' }}
            onClick={(e) => e.stopPropagation()}
          >
            {refAssetSymbol}
          </UiLink>
        ) : (
          '...'
        )}
      </Stack>
    );
  };

  if ('params' in dep) {
    const priceInUSD = BN(dep.price)
      .times(alias?.price || Q112)
      .div(Q112)
      .toString();

    return (
      <StyledItem item container alignItems="center" pl={1.5} pt={0.5}>
        <Grid
          item
          xs={5}
          lg={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            svg: {
              flexShrink: 0,
              maxHeight: '20px',
              width: '20px',
              mr: 1,
              display: 'block',
            },
          }}
        >
          {SOURCES.find((el) => el.id === dep.source)?.icon}
          <Typography variant="subtitle2">{dep.source}</Typography>
        </Grid>
        <Grid item xs={3} lg={3}>
          <Typography variant="body2">
            {numberWithDividers(formatNumber.getDisplayFromQ112(priceInUSD, 0, 4))}{' '}
          </Typography>
        </Grid>
        <Grid item xs={2} lg={3}>
          {getPair(dep)}
        </Grid>
      </StyledItem>
    );
  }

  const aliasForDep = dep.deps?.find((nestedDep) => nestedDep.operation === 'Alias');

  return (
    <StyledItem item container pl={{ xs: 1, lg: 1.5 }} pt={0.5}>
      <Grid item container>
        <Grid item>
          <Typography whiteSpace="nowrap">
            {dep.operation === 'Mul' ? 'Multiply' : dep.operation}
          </Typography>
        </Grid>
        <Grid item pl={1.5}>
          <Typography>
            {numberWithDividers(formatNumber.getDisplayFromQ112(dep.price, 0, 4))}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item pl={{ xs: 0.5, lg: 1.5 }} pt={0.5} width={'25rem'}>
        {dep.deps.map((nestedDep, index) => (
          <ValidatorDep
            key={`${nestedDep.operation}-${index}`}
            dep={nestedDep}
            alias={aliasForDep}
            ethProvider={ethProvider}
          />
        ))}
      </Grid>
    </StyledItem>
  );
};
