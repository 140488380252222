import { Grid, Typography } from '@mui/material';
import RHFTextField from 'src/components/form/RHFTextField';

export const ContractNameField = ({ text, disabled }: { text?: string; disabled?: boolean }) => (
  <>
    <Grid item xs={12} md={8}>
      <RHFTextField name="details.contractName" label="Contract name*" disabled={disabled} />
    </Grid>
    <Grid item xs={12} md={4}>
      <Typography
        variant="body2"
        sx={{
          color: 'text.secondary',
        }}
      >
        {text || (
          <>
            Must match the name specified in the code. For example, in{' '}
            <code>contract MyContract &#123;..&#125;</code>. <b>MyContract</b> is the contract name.
          </>
        )}
      </Typography>
    </Grid>
  </>
);
