import {
  Box,
  Button,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
} from '@mui/material';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FiltersTypes } from 'src/constants/filters';
import { useFilterByType } from 'src/hooks/useFilterByType';
import { ChainName } from 'src/types/Asset';
import { formatNumber } from 'src/utils/formatNumber';
import { StatusPoint } from 'src/components/StatusPoint';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiLink } from 'src/components/ui/UiLink';
import { numberWithDividers } from 'src/utils/number';
import { Scrollbar } from 'src/components/scrollbar/Scrollbar';
import { Iconify } from 'src/components/Iconify';
import { PriceCard } from 'src/components/PriceCard';
import { formatTimeStampUtc } from 'src/utils/time';
import { OracleType } from 'src/constants/oracle';
import { NetworkAssets } from 'src/hooks/useNetworkAssets';

const TABLE_HEAD_LABELS = [
  { id: 'symbol', label: 'Symbol', width: '12rem' },
  { id: 'type', label: 'Status', width: '10rem' },
  { id: 'price', label: 'Price', width: '12rem' },
  { id: 'date', label: 'Date' },
];

export const Prices: FC<{
  oracleType?: OracleType;
  networkId?: ChainName;
  networkAssets?: NetworkAssets;
  isLoading?: boolean;
}> = memo(({ oracleType, networkId, networkAssets, isLoading }) => {
  const [viewTable, setViewTable] = useState(false);
  const [query, setQuery] = useSearchParams();
  const [page, setPage] = useState(0);
  const elementsPerPage = 25;

  const queryParams = useMemo(() => {
    const queryPageNumber = query.get('page_number');
    const queryFilterType = query.get('filter_type');
    const page_number = queryPageNumber ? parseInt(queryPageNumber) : 0;
    const filter_type = queryFilterType ? queryFilterType : 'All';
    return { page_number, filter_type };
  }, [query]);

  const { filteredAssets, filterByTypeOptions, filterByType, setFilterByType } =
    useFilterByType(networkAssets);

  useEffect(() => {
    const { page_number, filter_type } = queryParams;

    setPage(page_number);
    setFilterByType(filter_type as FiltersTypes);
  }, [queryParams, setFilterByType, setQuery]);

  const getLinkToAsset = useCallback(
    (assetName: string) => `/oracles/${networkId}/${oracleType}/${assetName}`,
    [networkId, oracleType],
  );

  function handlePageChange(page: number) {
    if (!filteredAssets) return;

    if (page) {
      query.set('page_number', String(page));
    } else {
      query.delete('page_number');
    }
    setQuery(query);
  }

  const handleFilterType = (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
    if (newValue !== 'All') {
      query.set('filter_type', newValue);
    } else {
      query.delete('filter_type');
    }
    query.delete('page_number');
    setQuery(query);
  };

  if (!filteredAssets.length && !isLoading)
    return (
      <>
        <CardHeader title="Recent data feeds" sx={{ pl: 0 }} />
        <Typography mt="1rem">No data</Typography>
      </>
    );

  return (
    <>
      <CardHeader
        title="Recent data feeds"
        sx={{
          paddingTop: '2.5rem',
          flexWrap: 'wrap',
          whiteSpace: 'nowrap',
          pl: 0,
          '& .MuiCardHeader-action': {
            marginLeft: 'auto',
          },
        }}
      />

      <Box
        display="flex"
        alignItems={'center'}
        flexWrap={'wrap-reverse'}
        justifyContent="space-between"
        flexDirection={'row'}
        sx={{
          mt: 2,
          mb: viewTable ? 0 : 2,
        }}
      >
        <ToggleButtonGroup value={filterByType} exclusive onChange={handleFilterType}>
          {filterByTypeOptions
            .filter((elem) => !elem.disable)
            .map((el, i) => (
              <ToggleButton
                key={i}
                value={el.label}
                aria-label={el.label}
                sx={{
                  minWidth: '5rem',
                }}
              >
                {el.label}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
        <ButtonGroup color="inherit" sx={{ marginBottom: '1rem' }}>
          <Button variant={viewTable ? 'soft' : 'contained'} onClick={() => setViewTable(false)}>
            <Iconify icon={'material-symbols:grid-view-outline'} />
          </Button>
          <Button variant={!viewTable ? 'soft' : 'contained'} onClick={() => setViewTable(true)}>
            <Iconify icon={'ic:round-view-list'} />
          </Button>
        </ButtonGroup>
      </Box>

      {viewTable ? (
        <>
          <TablePagination
            component={'div'}
            count={filteredAssets.length}
            page={page}
            onPageChange={(_, page) => handlePageChange(page)}
            rowsPerPage={elementsPerPage}
            rowsPerPageOptions={[]}
            sx={{ borderTop: 'none', marginLeft: 'auto' }}
          />
          <TableContainer
            sx={{
              '& th:first-of-type, td:first-of-type': {
                paddingLeft: 3,
              },
            }}
          >
            <Table stickyHeader sx={{ overflowX: 'scroll' }}>
              <TableHeadCustom headLabel={TABLE_HEAD_LABELS} />

              <TableBody>
                {isLoading ? (
                  <PricesTableSkeleton />
                ) : (
                  filteredAssets
                    .slice(page * elementsPerPage, (page + 1) * elementsPerPage)
                    .map((asset, i) => (
                      <TableRow key={`${asset.address}-${i}`}>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          <UiLink to={getLinkToAsset(asset.name)}>
                            <Typography component="span" variant={'subtitle1'} whiteSpace="nowrap">
                              {asset.symbol}
                            </Typography>
                          </UiLink>
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          <StatusPoint />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                          ${numberWithDividers(formatNumber.getDisplayFromQ112(asset.value, 0, 4))}{' '}
                          {asset.currency === 'PCT' ? '%' : asset.currency || 'USD'}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                          {asset.updated ? formatTimeStampUtc(asset.updated) : ''}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {viewTable && !isLoading && (
            <TablePagination
              component={'div'}
              count={filteredAssets.length}
              page={page}
              onPageChange={(_, page) => handlePageChange(page)}
              rowsPerPage={elementsPerPage}
              rowsPerPageOptions={[]}
              sx={{ borderTop: 'none', marginLeft: 'auto' }}
            />
          )}
        </>
      ) : (
        <Scrollbar>
          <Grid
            container
            spacing={2}
            flexWrap="nowrap"
            pb={2}
            sx={{ overflow: isLoading ? 'hidden' : 'inherit' }}
          >
            {isLoading ? (
              <PricesSkeleton />
            ) : (
              filteredAssets?.map((asset) => (
                <Grid item md={2} key={asset.name} style={{ maxWidth: '100%' }}>
                  <UiLink to={getLinkToAsset(asset.name)} style={{ textDecoration: 'none' }}>
                    <PriceCard asset={asset} />
                  </UiLink>
                </Grid>
              ))
            )}
          </Grid>
        </Scrollbar>
      )}
    </>
  );
});

const PricesSkeleton = () => (
  <>
    <Grid item md={2} style={{ maxWidth: '100%' }}>
      <Skeleton variant="rounded" width="25rem" height="170px" />
    </Grid>
    <Grid item md={2} style={{ maxWidth: '100%' }}>
      <Skeleton variant="rounded" width="25rem" height="170px" />
    </Grid>
    <Grid item md={2} style={{ maxWidth: '100%' }}>
      <Skeleton variant="rounded" width="25rem" height="170px" />
    </Grid>
    <Grid item md={2} style={{ maxWidth: '100%' }}>
      <Skeleton variant="rounded" width="25rem" height="170px" />
    </Grid>
  </>
);

const PricesTableSkeleton = () => {
  const settings = [
    ['60px', undefined, '100px', '240px'],
    ['60px', undefined, '100px', '240px'],
    [undefined, undefined, undefined, '240px'],
    ['120px', undefined, '100px', '240px'],
    ['60px', undefined, '100px', '240px'],
  ];

  const renderSkeleton = (rowSettings: (string | undefined)[], index: number) => (
    <TableRow key={index}>
      {rowSettings.map((cellSize, index) => (
        <TableCell key={index} sx={{ verticalAlign: 'top' }}>
          {cellSize ? <Skeleton variant="text" width={cellSize} /> : <Skeleton variant="text" />}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <>
      {settings.map(renderSkeleton)}
      {settings.map(renderSkeleton)}
      {settings.map(renderSkeleton)}
      {settings.map(renderSkeleton)}
      {settings.map(renderSkeleton)}
    </>
  );
};
