import { Grid, Typography } from '@mui/material';
import RHFTextField from 'src/components/form/RHFTextField';

export const ContractCodeField = ({
  withText,
  disabled,
}: {
  withText?: boolean;
  disabled?: boolean;
}) => {
  return (
    <>
      <Grid item xs={12} md={8}>
        <RHFTextField
          name="details.contractCode"
          disabled={disabled}
          label="Contract code*"
          multiline
          rows={5}
          sx={{
            '& textarea': {
              resize: 'vertical',
            },
          }}
        />
      </Grid>
      {withText && (
        <Grid item xs={12} md={4}>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
            }}
          >
            We recommend using flattened code. This is necessary if your code utilizes a library or
            inherits dependencies. Use the{' '}
            <a
              href={'https://github.com/poanetwork/solidity-flattener'}
              target="_blank"
              rel="noopener noreferrer"
            >
              POA solidity flattener
            </a>{' '}
            or the{' '}
            <a
              href={'https://www.npmjs.com/package/truffle-flattener'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Truffle flattener
            </a>
          </Typography>
        </Grid>
      )}
    </>
  );
};
