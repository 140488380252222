import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { api } from 'src/api';
import { Iconify } from 'src/components/Iconify';
import { useFetch } from 'src/hooks/useFetch';
import { ApiStatsResp } from 'src/types/MultidataApi';
import { numberWithDividers } from 'src/utils/number';
import StatisticCard from 'src/components/StatisticCard';
import { BN } from 'src/utils/bignumber';

const statsCards: {
  icon: string;
  title: string;
  param: keyof Omit<ApiStatsResp, 'gas_prices'>;
  formatter?: (num: any) => any;
}[] = [
  {
    icon: 'clarity:block-line',
    title: 'Total blocks',
    param: 'total_blocks',
    formatter: numberWithDividers,
  },
  {
    icon: 'material-symbols:swap-horiz',
    title: 'Total transactions',
    param: 'total_transactions',
    formatter: numberWithDividers,
  },
  {
    icon: 'mdi:clock-time-five-outline',
    title: 'Average block time',
    param: 'average_block_time',
    formatter(time) {
      const seconds = Number(time) / 1000;
      if (seconds < 60) return `${seconds} sec`;
      return `${seconds / 60} min`;
    },
  },
];

export const Statistic = () => {
  const { fetchData, data: stats } = useFetch<ApiStatsResp>('failed to get stats information: ');

  useEffect(() => {
    fetchData(api.multidata.getStatsCounters());
  }, []);

  if (!stats) return null;

  return (
    <Grid container spacing={2} alignItems="stretch">
      {statsCards.map((el) => (
        <Grid item key={el.param} xs={12} md={6} lg={6} xl={6}>
          <StatisticCard
            title={el.title}
            total={el.formatter ? el.formatter(stats[el.param]) : stats[el.param]}
            icon={<Iconify icon={el.icon} />}
          />
        </Grid>
      ))}
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <StatisticCard
          title="Gas tracker"
          total={`${
            stats.gas_prices?.average
              ? BN(stats.gas_prices.average).times(1000000000).toString()
              : ''
          } Wei`}
          icon={<Iconify icon="mdi:gas-station" width={30} />}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <StatisticCard
          title="Supported networks"
          total={'23'}
          icon={<Iconify icon="eos-icons:blockchain" />}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6}>
        <StatisticCard
          title="Assets price feeds"
          total={'1000+'}
          icon={<Iconify icon="gg:feed" />}
        />
      </Grid>
    </Grid>
  );
};
