import { TextField, TextFieldProps, useTheme } from '@mui/material';

export const UiTextFilled = (props: TextFieldProps) => {
  const theme = useTheme();

  return (
    <TextField
      sx={{
        width: '100%',
        '& .MuiInputBase-root': { background: theme.palette.grey[200] },
      }}
      multiline
      {...props}
    />
  );
};
