import { Box, Card, CardContent, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { useFetch } from 'src/hooks/useFetch';
import { ApiNftInstances } from 'src/types/MultidataApi';
import { NftImage } from '../NftImage';
import { UiHash } from '../UiAddress';
import { UiLink } from '../ui/UiLink';
import { CONFIG } from 'src/app.config';
import { usePaginationWithSearchParams } from 'src/hooks/usePaginationWithSearchParams';
import { UiPagination } from 'src/components/ui/UiPagination';

export const Inventory = () => {
  const { address } = useParams();
  const { fetchData, isLoading, txStatus, data } = useFetch<ApiNftInstances>(
    'failed to get NFT instances: ',
  );

  const { handlePageChange, page, paginationCount, prevPages, queryArgs } =
    usePaginationWithSearchParams(data?.next_page_params || null);

  useEffect(() => {
    if (!address) return;
    fetchData(api.multidata.getNftInstances(address, queryArgs ? `?${queryArgs}` : ''));
  }, [address, queryArgs]);

  if (isLoading) return <p>Loading...</p>;

  if (txStatus.error) return <p>{txStatus.error}</p>;

  if (!data) return null;

  return (
    <>
      <UiPagination
        page={page}
        paginationCount={paginationCount}
        handlePageChange={handlePageChange}
        disabled={isLoading}
        emptyPrevPages={prevPages.length === 0}
        sx={{ marginBottom: '1.5rem' }}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))',
          gridGap: '2rem',
        }}
      >
        {data.items.map((item) => (
          <Card
            key={item.id}
            sx={{
              position: 'relative',
              boxShadow: 'none',
              backgroundColor: (theme) => theme.palette.background.neutral,
            }}
          >
            <UiLink
              to={`/${CONFIG.routes.tokenPage}/${address}/instance/${item.id}`}
              sx={{
                textDecoration: 'none',
                position: 'static',
                ':before': {
                  content: "''",
                  cursor: 'inherit',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 0,
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <NftImage imgUrl={item.image_url || ''} borderRadius={0} minHeight={'12rem'} />
            </UiLink>
            <CardContent>
              <Typography>ID# {item.id}</Typography>
              <Tooltip
                placement="top"
                title={item.owner.hash}
                componentsProps={{
                  tooltip: {
                    style: {
                      maxWidth: 'max-content',
                    },
                  },
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Typography>Owner</Typography>

                  <UiHash hash={item.owner.hash} cutting zIndex={1} />
                </Stack>
              </Tooltip>
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};
