import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ApiContractMethod } from 'src/types/MultidataApi';
import { ContractInputs } from './ContractInputs';
import { ethers } from 'ethers';
import { UiHash } from 'src/components/UiAddress';
import { CONFIG } from 'src/app.config';

export const WriteForm = ({
  method,
  contract,
  disabledSubmit = false,
  walletNetworkId,
}: {
  method: ApiContractMethod;
  contract: ethers.Contract | null;
  disabledSubmit: boolean;
  walletNetworkId: number | null;
}) => {
  const { address } = useParams();
  const { register, handleSubmit } = useForm();

  const [tx, setTx] = useState<ethers.ContractTransaction | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (data: any) => {
    setErrorMessage('');
    if (!address || !contract || !walletNetworkId) return;
    if (walletNetworkId !== CONFIG.chains.chunk.networkId) {
      setErrorMessage(
        'Wrong network detected, please make sure you are switched to the correct network, and try again',
      );
    } else {
      try {
        const params = Object.values(data);
        const tx: ethers.ContractTransaction = await contract[method.name](...params);
        setTx(tx);
      } catch (err: any) {
        console.error(err);
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContractInputs
        inputs={method.inputs}
        lastElementIndex={method.inputs.length - 1}
        register={register}
      />
      <Button
        variant="contained"
        type="submit"
        sx={{ marginTop: '1rem' }}
        disabled={disabledSubmit}
      >
        query
      </Button>
      {tx && (
        <Typography fontSize="1rem" mt="0.5rem" sx={{ display: 'flex', alignItems: 'center' }}>
          tx status:{' '}
          <UiHash hash={tx.hash} sx={{ marginLeft: '0.2rem', fontSize: '1rem' }} cutting withCopy />
        </Typography>
      )}
      {errorMessage && (
        <Typography fontSize="0.875rem" mt="0.5rem" color="error" sx={{ wordBreak: 'break-all' }}>
          {errorMessage}
        </Typography>
      )}
    </form>
  );
};
