import { Grid, Paper, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { Iconify } from 'src/components/Iconify';
import { Label } from 'src/components/label/Label';
import { UiHash } from 'src/components/UiAddress';
import { useTimePast } from 'src/hooks/useTimePast';
import { MulV1Feed, MulV1Validator } from 'src/types/MultidataV1Api';
import { formatTimeStampUtc } from 'src/utils/time';

export const Validators: FC<{
  currentFeed: MulV1Feed | null | undefined;
  networkValidators: MulV1Validator[] | null;
  isLoading?: boolean;
}> = ({ currentFeed, networkValidators, isLoading }) => {
  const lastUpdated =
    currentFeed && currentFeed.updated ? formatTimeStampUtc(currentFeed.updated) : '';

  const timePast = useTimePast(new Date(currentFeed?.updated || '').getTime());

  if (!networkValidators && !isLoading) return null;

  return (
    <>
      <Typography variant="h6" sx={{ mt: '1.5rem', mb: '1.25rem' }}>
        {isLoading || !networkValidators
          ? 'Data validators quorum'
          : `Data validators (quorum ${currentFeed?.quorum}/${networkValidators?.length})`}
      </Typography>

      <Grid container spacing={4}>
        {isLoading || !networkValidators ? (
          <ValidatorsSkeleton />
        ) : (
          networkValidators?.map((el, i) => (
            <Grid item key={`${el.address}-${i}`} xs={12} sm={6} lg={3}>
              <Paper
                sx={{
                  py: 2.5,
                  px: 4,
                  display: 'grid',
                  justifyItems: 'center',
                  gridGap: 1,
                  height: '100%',
                  background: (theme) => theme.palette.background.neutral,
                }}
              >
                <Iconify icon="grommet-icons:oracle" width={32} />

                <UiHash
                  networkId={currentFeed?.name}
                  hash={el.address}
                  variant="subtitle1"
                  name={el.name}
                  cutting
                  textAlign="center"
                  target="_blank"
                  rel="noopener"
                />

                <Typography variant="body2">{lastUpdated}</Typography>
                <Typography variant="body2">{timePast} ago</Typography>
                {el.in_quorum ? (
                  <Label
                    variant="soft"
                    color="success"
                    startIcon={
                      <Iconify
                        icon="clarity:success-standard-solid"
                        color={(theme) => theme.palette.success.main}
                      />
                    }
                  >
                    Yes
                  </Label>
                ) : (
                  <Label
                    variant="soft"
                    color="error"
                    startIcon={
                      <Iconify
                        icon="material-symbols:error-rounded"
                        color={(theme) => theme.palette.error.main}
                      />
                    }
                  >
                    No
                  </Label>
                )}
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};

const ValidatorsSkeleton = () => (
  <>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
    <Grid item xs={12} sm={6} lg={3}>
      <Skeleton variant="rounded" height="146px" />
    </Grid>
  </>
);
