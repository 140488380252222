import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { MenuItem, Typography, Grid } from '@mui/material';

import { RHFSelect } from 'src/components/form/RHFSelect';

export const CompilerField: FC<{
  compilers?: string[];
  withText?: boolean;
  disabled?: boolean;
}> = ({ compilers, withText, disabled }) => {
  const { setValue, watch } = useFormContext();

  const values = watch();
  const handleSelectCompiler = useCallback(
    (name: string) => {
      setValue('compiler', compilers?.find((compiler) => compiler === name) || '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, values.items],
  );

  return (
    <>
      <Grid item xs={12} md={8}>
        <RHFSelect
          name="details.compilerVersion"
          label="Compiler*"
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
        >
          {compilers?.map((compiler) => (
            <MenuItem
              key={compiler}
              value={compiler}
              onClick={() => handleSelectCompiler(compiler)}
            >
              {compiler}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>
      {withText && (
        <Grid item xs={12} md={4}>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
            }}
          >
            The compiler version is specified in <code>pragma solidity X.X.X</code>. Use the
            compiler version rather than the nightly build. If using the Solidity compiler, run{' '}
            <code>solc —version</code> to check.
          </Typography>
        </Grid>
      )}
    </>
  );
};
