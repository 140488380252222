import React from 'react';
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useState } from 'react';
import { ApiTokenInstance } from 'src/types/MultidataApi';
import { UiTextFilled } from 'src/components/ui/UiTextFilled';
import { UiGridContainerNoWrap, UiGridItem } from 'src/components/ui/UiGrid';
import { spaceBetweenCell } from 'src/utils/cssStyles';
import { UiLink } from 'src/components/ui/UiLink';

export const Metadata = ({ tokenInstance }: { tokenInstance: ApiTokenInstance }) => {
  const [rawInputType, setRawInputType] = useState('table');
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setRawInputType(event.target.value);
  };

  return tokenInstance.metadata ? (
    <Box>
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={rawInputType}
        onChange={handleChange}
        sx={{
          width: '100%',
          maxWidth: '6.2rem',
          marginBottom: '0.5rem',
          '& .MuiInputBase-input': { padding: '4px 6px' },
        }}
      >
        <MenuItem value={'table'}>Table</MenuItem>
        <MenuItem value={'json'}>JSON</MenuItem>
      </Select>
      {rawInputType === 'json' ? (
        <UiTextFilled maxRows={12} value={JSON.stringify(tokenInstance.metadata, null, 4)} />
      ) : (
        <UiGridContainerNoWrap>
          {tokenInstance.metadata.name && (
            <>
              <UiGridItem sx={spaceBetweenCell}>
                <Typography>Name</Typography>
              </UiGridItem>
              <UiGridItem>
                <Typography>{tokenInstance.metadata.name}</Typography>
              </UiGridItem>
            </>
          )}
          {tokenInstance.metadata.description && (
            <>
              <UiGridItem sx={spaceBetweenCell}>
                <Typography>Description</Typography>
              </UiGridItem>
              <UiGridItem>
                <Typography>{tokenInstance.metadata.description}</Typography>
              </UiGridItem>
            </>
          )}
          {tokenInstance.metadata.image && (
            <>
              <UiGridItem sx={spaceBetweenCell}>
                <Typography>Image</Typography>
              </UiGridItem>
              <UiGridItem>
                <Typography>
                  <UiLink
                    to={tokenInstance.metadata.image}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tokenInstance.metadata.image}
                  </UiLink>
                </Typography>
              </UiGridItem>
            </>
          )}
          {tokenInstance.metadata.external_url && (
            <>
              <UiGridItem sx={spaceBetweenCell}>
                <Typography>External</Typography>
              </UiGridItem>
              <UiGridItem>
                <Typography>
                  <UiLink
                    to={tokenInstance.metadata.external_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tokenInstance.metadata.external_url}
                  </UiLink>
                </Typography>
              </UiGridItem>
            </>
          )}
          {tokenInstance.metadata.attributes && (
            <>
              <UiGridItem sx={{ alignItems: 'flex-start' }}>
                <Typography>Attributes</Typography>
              </UiGridItem>
              <UiGridItem>
                <UiGridContainerNoWrap>
                  {tokenInstance.metadata.attributes.map((attribute) => {
                    return Object.entries(attribute).map(([key, value]) => (
                      <React.Fragment key={key}>
                        <UiGridItem sx={{ padding: 0 }}>
                          <Typography>{key}</Typography>
                        </UiGridItem>
                        <UiGridItem sx={{ padding: 0 }}>
                          <Typography>{value}</Typography>
                        </UiGridItem>
                      </React.Fragment>
                    ));
                  })}
                </UiGridContainerNoWrap>
              </UiGridItem>
            </>
          )}
        </UiGridContainerNoWrap>
      )}
    </Box>
  ) : (
    <Typography mt="2rem">There is no metadata for this NFT</Typography>
  );
};
