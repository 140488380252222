import { Box, Container, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header } from 'src/components/header/Header';
import { HEADER, NAV } from 'src/config-global';
import useResponsive from 'src/hooks/useResponsive';
import { FCC } from 'src/types/FCC';

const SPACING = 8;

export const MainLayout: FCC = () => {
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          py: `${HEADER.H_MOBILE + SPACING}px`,
          ...(isDesktop && {
            px: 2,
            py: `${HEADER.H_MAIN_DESKTOP + SPACING * 2}px`,
            width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
          }),
        }}
      >
        <Container
          sx={{
            maxWidth: { xs: 'none', xl: '80%' },
            minWidth: {
              xl: `${theme.breakpoints.values.xl}px`,
            },
          }}
        >
          <Header />
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};
