import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CONFIG } from 'src/app.config';
import { ApiTransactionLogsResp } from 'src/types/MultidataApi';
import { spaceBetweenCell, wordBreak } from 'src/utils/cssStyles';
import { UiGridContainer, UiGridItem } from 'src/components/ui/UiGrid';
import { UiLink } from 'src/components/ui/UiLink';
import { UiDivider } from 'src/components/ui/UiDivider';
import { useFetch } from 'src/hooks/useFetch';
import { api } from 'src/api';
import { LogData } from 'src/components/transactionDetails/LogData';
import { LogTopic } from 'src/components/transactionDetails/LogTopic';

export const TransactionLogs = () => {
  const { hash } = useParams();

  const {
    fetchData,
    isLoading,
    txStatus,
    data: logs,
  } = useFetch<ApiTransactionLogsResp>('failed to get transaction logs information: ');

  useEffect(() => {
    if (!hash) return;
    fetchData(api.multidata.getLogs(hash));
  }, [hash]);

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : txStatus.error > 0 ? (
        <p>{txStatus.error}</p>
      ) : logs && logs.items.length > 0 ? (
        logs.items.map((log, index) => (
          <React.Fragment key={index}>
            {index > 0 && <UiDivider />}
            <UiGridContainer>
              <UiGridItem sx={spaceBetweenCell}>
                <Typography>Address</Typography>
              </UiGridItem>
              <UiGridItem sx={{ justifyContent: 'space-between' }}>
                <UiLink to={`/${CONFIG.routes.addressPage}/${log.address.hash}`} sx={wordBreak}>
                  <Typography>{log.address.name ? log.address.name : log.address.hash}</Typography>
                </UiLink>
              </UiGridItem>

              {log.decoded && (
                <>
                  <UiGridItem sx={spaceBetweenCell}>
                    <Typography>Method Id</Typography>
                    <UiGridItem display={{ xs: 'flex', md: 'none' }}>
                      <Typography>{log.decoded.method_id}</Typography>
                    </UiGridItem>
                  </UiGridItem>
                  <UiGridItem display={{ xs: 'none', md: 'flex' }}>
                    <Typography>{log.decoded.method_id}</Typography>
                  </UiGridItem>
                  <UiGridItem>
                    <Typography>Call</Typography>
                  </UiGridItem>
                  <UiGridItem>
                    <Typography>{log.decoded.method_call}</Typography>
                  </UiGridItem>
                </>
              )}

              <UiGridItem sx={{ alignItems: 'flex-start', ...spaceBetweenCell }}>
                <Typography>Topics</Typography>
              </UiGridItem>
              <UiGridItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                {log.topics.map(
                  (addressTopic, index) =>
                    addressTopic && (
                      <LogTopic
                        key={`addressTopic${index}`}
                        topic={addressTopic}
                        index={index}
                        decodedData={log?.decoded?.parameters.filter((item) => item.indexed)}
                      />
                    ),
                )}
              </UiGridItem>
              <UiGridItem sx={{ alignItems: 'flex-start', ...spaceBetweenCell }}>
                <Typography>Data</Typography>
              </UiGridItem>
              <UiGridItem sx={{ alignItems: 'flex-start' }}>
                <LogData log={log} />
              </UiGridItem>
            </UiGridContainer>
          </React.Fragment>
        ))
      ) : (
        <Typography>There are no logs for this transaction.</Typography>
      )}
    </div>
  );
};
