import { Box } from '@mui/material';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OracleTypeTabs } from 'src/components/OracleTypeTabs';
import { Prices } from 'src/components/Prices';
import { Validators } from 'src/components/Validators';
import { UiProgress } from 'src/components/ui/UiProgress';
import { DEFAULT_CHAIN_ID } from 'src/constants/chains';
import { OracleType } from 'src/constants/oracle';
import { useFeed } from 'src/hooks/useFeed';
import { useNetworkAssets } from 'src/hooks/useNetworkAssets';
import { useNetworkValidators } from 'src/hooks/useNetworkValidators';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { ChainName } from 'src/types/Asset';
import { RemoteDataStatus } from 'src/utils/remoteData';

export const NetworkId = () => {
  const { networkId = DEFAULT_CHAIN_ID } = useParams<{
    networkId: ChainName;
  }>();
  const [tab, setTab] = useState<OracleType | undefined>();
  const { isLoadingFeeds, feedsTxStatus } = useContext(AssetsCtx);
  const { feedForView, dataFeedForView } = useFeed(networkId, tab);
  const { networkValidators, isLoadingValidators } = useNetworkValidators(dataFeedForView?.network);
  const { networkAssets, isLoading: isNetworkAssetsLoading } = useNetworkAssets(
    dataFeedForView?.network,
    dataFeedForView?.feedAddress,
  );

  if (isLoadingFeeds || feedsTxStatus?.status === RemoteDataStatus.notAsked)
    return <UiProgress text="Loading feeds..." />;

  return (
    <Box mt="2rem">
      <OracleTypeTabs
        tab={tab}
        setTab={setTab}
        currentNetwork={networkId}
        isDisabled={isNetworkAssetsLoading}
      />
      <Prices
        oracleType={tab}
        networkId={networkId}
        networkAssets={networkAssets}
        isLoading={isNetworkAssetsLoading}
      />
      <Validators
        currentFeed={feedForView}
        networkValidators={networkValidators}
        isLoading={isLoadingValidators}
      />
    </Box>
  );
};
