import { Grid } from '@mui/material';
import { RHFCheckbox } from 'src/components/form/RHFCheckbox';

export const NightlyBuildsField = ({ disabled }: { disabled?: boolean }) => {
  return (
    <Grid item xs={12} md={8}>
      <RHFCheckbox
        name="details.withNightlyBuilds"
        label="Include nightly builds"
        disabled={disabled}
      />
    </Grid>
  );
};
