import { FC } from 'react';
import { Typography, Grid } from '@mui/material';

import { NightlyBuildsField } from 'src/components/form/fields/NightlyBuildsField';
import { CompilerField } from 'src/components/form/fields/CompilerField';
import { OptimizationField } from 'src/components/form/fields/OptimizationField';
import { LibrariesField } from 'src/components/form/fields/LibrariesField';
import { EVMversionField } from 'src/components/form/fields/EVMversionField';
import { UploadField } from 'src/components/form/fields/UploadField';

export const SolidityMultipartForm: FC<{
  evmVersions?: string[];
  compilers?: string[];
  disabled?: boolean;
}> = ({ evmVersions, compilers, disabled }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: 'text.disabled' }}>
          Contract verification via Solidity (multi-part files)
        </Typography>
      </Grid>

      <NightlyBuildsField disabled={disabled} />
      <CompilerField compilers={compilers} withText disabled={disabled} />
      <EVMversionField versions={evmVersions} disabled={disabled} />
      <OptimizationField disabled={disabled} />
      <UploadField
        text="Upload all Solidity or Yul contract source files."
        buttonLabel="Sources *.sol or *.yul files"
        accept=".sol"
        disabled={disabled}
      />
      <LibrariesField disabled={disabled} />
    </>
  );
};
