import { Card, Stack, Typography, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import { Iconify } from 'src/components/Iconify';
import { UiHash } from 'src/components/UiAddress';
import { useTimePast } from 'src/hooks/useTimePast';
import { ApiTransaction } from 'src/types/MultidataApi';
import { UiLink } from './ui/UiLink';
import { CONFIG } from 'src/app.config';
import { shortenHash } from 'src/utils/ui';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { Label } from 'src/components/label/Label';

interface Props {
  tx: ApiTransaction;
  writeChain: string | undefined;
}

export const LatestTxsItem: FC<Props> = ({ tx, writeChain }) => {
  const timePast = useTimePast(tx.timestamp);
  const theme = useTheme();
  const { participants } = useContext(AssetsCtx);

  return (
    <Card
      sx={{
        width: '100%',
        minWidth: { xs: '80vw', sm: '25rem' },
        boxShadow: 0,
        color: theme.palette.text.primary,
        bgcolor: theme.palette.background.neutral,
      }}
    >
      <UiLink to={`${CONFIG.routes.txPage}/${tx.hash}`}>
        <Iconify
          icon={'eva:diagonal-arrow-right-up-fill'}
          sx={{
            p: 1.5,
            top: 24,
            right: 24,
            width: 48,
            height: 48,
            borderRadius: '50%',
            position: 'absolute',
            color: theme.palette.background.neutral,
            bgcolor: theme.palette.grey[600],
          }}
        />
      </UiLink>

      <Stack spacing={1} sx={{ p: 3 }}>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          {timePast} ago
        </Typography>
        <UiLink to={`${CONFIG.routes.txPage}/${tx.hash}`} color={theme.palette.text.primary}>
          <Typography variant="h3">{shortenHash(tx.hash, 6, 6)}</Typography>
        </UiLink>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle2" component="span">
              From:
            </Typography>
            <UiHash
              hash={tx.from.hash}
              cutting
              isContract={tx.from.is_contract}
              isValidator={!!participants[tx.from.hash]}
              color={theme.palette.primary.darker}
              sx={{
                'a, svg': {
                  color: theme.palette.text.primary,
                },
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle2" component="span">
              To:
            </Typography>
            {tx.to?.hash && (
              <UiHash
                hash={tx.to.hash}
                cutting
                isContract={tx.to.is_contract}
                isValidator={!!participants[tx.to.hash]}
                sx={{
                  'a, svg': {
                    color: theme.palette.text.primary,
                  },
                }}
              />
            )}
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ opacity: writeChain ? '1' : '0' }}>
          <Typography variant="subtitle2" component="span">
            Interacted with:
          </Typography>
          <Label variant="filled">{writeChain}</Label>
        </Stack>
      </Stack>
    </Card>
  );
};
