import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
import { Iconify } from 'src/components/Iconify';
import { Label } from 'src/components/label/Label';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiDivider, UiVerticalDivider } from 'src/components/ui/UiDivider';
import { UiGridContainer, UiGridItem } from 'src/components/ui/UiGrid';
import { UiInfoTooltip } from 'src/components/ui/UiInfoTooltip';
import { ButtonLink, UiLink } from 'src/components/ui/UiLink';
import { UiStatsRange, UiStatsRangeVariant } from 'src/components/ui/UiStats';
import { UiTextFilled } from 'src/components/ui/UiTextFilled';
import { useFetch } from 'src/hooks/useFetch';
import { useTimePast } from 'src/hooks/useTimePast';
import { useContractChainMap } from 'src/providers/ContractChainMapProvider';
import { ApiTransaction } from 'src/types/MultidataApi';
import { spaceBetweenCell, wordBreak } from 'src/utils/cssStyles';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithCommas } from 'src/utils/misc';
import { NetworkLabel } from 'src/components/NetworkLabel';
import { ethers } from 'ethers';
import { DisplayMethod } from './DisplayMethod';

const TransactionPastTime = ({ trx }: { trx: ApiTransaction }) => {
  const txTimePast = useTimePast(trx.timestamp);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Iconify icon="ic:round-access-time" />
      <Typography ml="0.25rem">{txTimePast} ago</Typography>
      <UiVerticalDivider />
      <Typography>{trx.timestamp}</Typography>
    </Box>
  );
};

export const TransactionDetails = () => {
  const { hash } = useParams();
  const theme = useTheme();
  const {
    fetchData,
    isLoading,
    txStatus,
    data: tx,
  } = useFetch<ApiTransaction>('failed to get transaction information: ');
  const { getWriteChain } = useContractChainMap();

  const [viewMoreDetails, setViewMoreDetails] = useState(false);
  const [rawInputType, setRawInputType] = useState('hex');
  const [open, setOpen] = useState(false);

  const writeChain = getWriteChain(tx?.to?.hash);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setRawInputType(event.target.value);
  };

  const utf8InputValue = useMemo(
    () => (tx?.raw_input ? new TextDecoder().decode(ethers.utils.arrayify(tx.raw_input)) : ''),
    [tx?.raw_input],
  );

  useEffect(() => {
    if (!hash) return;
    fetchData(api.multidata.getTxInfo(hash));
  }, [hash]);

  useEffect(() => {
    if (!viewMoreDetails) return;

    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }, [viewMoreDetails]);

  const resultContent = (tx: ApiTransaction) => (
    <>
      <Label
        variant="soft"
        color={tx.status === 'ok' ? 'success' : 'error'}
        startIcon={
          <Iconify
            icon={
              tx.status === 'ok'
                ? 'clarity:success-standard-solid'
                : 'material-symbols:error-rounded'
            }
            color={(theme) =>
              tx.status === 'ok' ? theme.palette.success.main : theme.palette.error.main
            }
            mr="0.5rem"
          />
        }
      >
        {tx.status === 'ok' ? 'Success' : 'Error'}
      </Label>
      {tx.status === 'error' && (
        <>
          <UiVerticalDivider />
          <Typography>{tx.result}</Typography>
        </>
      )}
    </>
  );

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : txStatus.error ? (
        <p>{txStatus.error}</p>
      ) : (
        tx && (
          <UiGridContainer>
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Address (external or contract) sending the transaction" />
              <Typography>From</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography sx={wordBreak}>
                <UiLink to={`/${CONFIG.routes.addressPage}/${tx.from.hash}`}>{tx.from.hash}</UiLink>
              </Typography>
            </UiGridItem>
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Address (external or contract) receiving the transaction" />
              <Typography>To</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography sx={wordBreak}>
                {tx.to ? (
                  <UiLink to={`/${CONFIG.routes.addressPage}/${tx.to.hash}`}>{tx.to.hash}</UiLink>
                ) : (
                  '-'
                )}
              </Typography>
            </UiGridItem>
            <UiDivider />

            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Unique character string (TxID) assigned to every verified transaction" />
              <Typography>Transaction Hash</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography sx={wordBreak}>{tx.hash}</Typography>
            </UiGridItem>

            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Current transaction state: Success, Failed (Error), or Pending (In Process)" />
              <Typography>Result</Typography>

              <UiGridItem display={{ xs: 'flex', md: 'none' }}>{resultContent(tx)}</UiGridItem>
            </UiGridItem>
            <UiGridItem display={{ xs: 'none', md: 'flex' }}>{resultContent(tx)}</UiGridItem>

            {tx.revert_reason && (
              <>
                <UiGridItem sx={{ alignItems: 'flex-start', ...spaceBetweenCell }}>
                  <UiInfoTooltip title="The revert reason of the transaction" />
                  <Typography>Revert Reason</Typography>
                </UiGridItem>
                <UiGridItem>
                  <DisplayMethod method={tx.revert_reason} />
                </UiGridItem>
              </>
            )}

            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Block number containing the transaction" />
              <Typography>Block</Typography>
            </UiGridItem>
            <UiGridItem>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>
                  <UiLink to={`/${CONFIG.routes.blockPage}/${tx.block}`}>{tx.block}</UiLink>
                </Typography>
                <UiVerticalDivider />
                <Typography sx={{ color: theme.palette.grey[500] }}>
                  Confirmed by {numberWithCommas(+tx.confirmations)} blocks
                </Typography>
              </Box>
            </UiGridItem>
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Date & time of transaction inclusion, including length of time for confirmation" />
              <Typography>Timestamp</Typography>
            </UiGridItem>
            <UiGridItem>
              <TransactionPastTime trx={tx} />
            </UiGridItem>
            {writeChain && (
              <>
                <UiGridItem sx={spaceBetweenCell}>
                  <UiInfoTooltip title="Oracle write target chain" />
                  <Typography>Interacted with</Typography>
                  <UiGridItem display={{ xs: 'flex', md: 'none' }}>
                    <NetworkLabel tx={tx} color="primary" />
                  </UiGridItem>
                </UiGridItem>
                <UiGridItem display={{ xs: 'none', md: 'flex' }}>
                  <NetworkLabel tx={tx} color="primary" />
                </UiGridItem>
              </>
            )}
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Value sent in the native token (and USD) if applicable" />
              <Typography>Value</Typography>
              <UiGridItem display={{ xs: 'flex', md: 'none' }}>
                <Typography>{formatNumber.getDisplay(tx.value)} CHUNK</Typography>
              </UiGridItem>
            </UiGridItem>
            <UiGridItem display={{ xs: 'none', md: 'flex' }}>
              <Typography>{formatNumber.getDisplay(tx.value)} CHUNK</Typography>
            </UiGridItem>

            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Total transaction fee" />
              <Typography>Transaction fee</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography>{formatNumber.getDisplay(tx.fee.value, 18, 4, false)} CHUNK</Typography>
            </UiGridItem>
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Price per unit of gas specified by the sender. Higher gas prices can prioritize transaction inclusion during times of high usage" />
              <Typography>Gas price</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography>{formatNumber.getDisplay(tx.gas_price, 9, 9)} Gwei</Typography>
            </UiGridItem>
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Price per unit of gas specified by the sender. Higher gas prices can prioritize transaction inclusion during times of high usage" />
              <Typography>Gas usage & limit by txn</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography>{numberWithCommas(tx.gas_used ? +tx.gas_used : 0)}</Typography>
              <UiVerticalDivider />
              <Typography>{numberWithCommas(+tx.gas_limit)}</Typography>
              <UiStatsRange
                value={((tx.gas_used ? +tx.gas_used : 0) / +tx.gas_limit) * 100}
                variant={UiStatsRangeVariant.positive}
              />
            </UiGridItem>
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Base Fee refers to the network Base Fee at the time of the block, while Max Fee & Max Priority Fee refer to the max amount a user is willing to pay for their tx & to give to the miner respectively" />
              <Typography>Gas fees (Gwei)</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography>
                Base: <b>{formatNumber.getDisplay(tx.base_fee_per_gas, 9)}</b>
              </Typography>
            </UiGridItem>
            {tx.tx_burnt_fee && (
              <>
                <UiGridItem sx={spaceBetweenCell}>
                  <UiInfoTooltip title="Amount burned for this transaction. Equals Block Base Fee per Gas * Gas Used" />
                  <Typography>Burnt fees</Typography>
                </UiGridItem>
                <UiGridItem>
                  <Typography>{formatNumber.getDisplay(tx.tx_burnt_fee)} CHUNK</Typography>
                </UiGridItem>
              </>
            )}
            <Box sx={{ gridColumn: 'span 2 / span 2', margin: '1rem 0' }}>
              <ButtonLink
                onClick={() => {
                  setViewMoreDetails((isView) => !isView);
                }}
              >
                {viewMoreDetails ? 'Hide details' : 'View details'}
              </ButtonLink>
            </Box>
            {viewMoreDetails && (
              <>
                <UiGridItem sx={spaceBetweenCell}>
                  <UiInfoTooltip title="Other data related to this transaction" />
                  <Typography>Other</Typography>
                </UiGridItem>
                <UiGridItem>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      Txn type: <b>{tx.type}</b>
                    </Typography>
                    <UiVerticalDivider />
                    <Typography>
                      Nonce: <b>{tx.nonce}</b>
                    </Typography>
                    <UiVerticalDivider />
                    <Typography>
                      Position: <b>{tx.position}</b>
                    </Typography>
                  </Box>
                </UiGridItem>
                <UiGridItem sx={{ alignItems: 'flex-start', ...spaceBetweenCell }}>
                  <UiInfoTooltip title="Binary data included with the transaction. See logs tab for additional info" />
                  <Typography>Raw input</Typography>
                </UiGridItem>
                <UiGridItem>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Select
                      open={open}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={rawInputType}
                      onChange={handleChange}
                      sx={{
                        maxWidth: '6.2rem',
                        marginBottom: '0.5rem',
                        '& .MuiInputBase-input': { padding: '4px 6px' },
                      }}
                    >
                      <MenuItem value={'hex'}>Hex</MenuItem>
                      <MenuItem value={'utf8'}>UTF-8</MenuItem>
                    </Select>
                    <UiTextFilled
                      maxRows={8}
                      value={rawInputType === 'hex' ? tx.raw_input : utf8InputValue}
                    />
                  </Box>
                </UiGridItem>
                {tx.decoded_input && (
                  <>
                    <UiGridItem sx={{ alignItems: 'flex-start', ...spaceBetweenCell }}>
                      <UiInfoTooltip title="Decoded input data" />
                      <Typography>Decoded input data</Typography>
                    </UiGridItem>
                    <UiGridItem>
                      <DisplayMethod method={tx.decoded_input} />
                    </UiGridItem>
                  </>
                )}
              </>
            )}
          </UiGridContainer>
        )
      )}
    </div>
  );
};
