import { Typography, useTheme } from '@mui/material';
import { FCC } from 'src/types/FCC';

export const UiInfo: FCC = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        fontSize: '0.875rem',
        padding: '0 0.5rem',
        background: theme.palette.grey[200],
        borderRadius: '4px',
        width: 'max-content',
      }}
    >
      {children}
    </Typography>
  );
};
