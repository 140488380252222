import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
import { Fade } from 'src/components/Fade';
import { PageTitle } from 'src/components/PageTitle';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiLink } from 'src/components/ui/UiLink';
import { UiStatsRange } from 'src/components/ui/UiStats';
import { UiHash } from 'src/components/UiAddress';
import { useFetch } from 'src/hooks/useFetch';
import { useNewBlock } from 'src/hooks/useNewBlock';
import useResponsive from 'src/hooks/useResponsive';
import { ApiBlock, ApiBlocksResp, NextPageParams } from 'src/types/MultidataApi';
import { numberWithCommas } from 'src/utils/misc';
import { timeSince } from 'src/utils/time';

export const Blocks = () => {
  const [query, setQuery] = useSearchParams();
  const [page, setPage] = useState(1);
  const [firstPage, setFirstPage] = useState<NextPageParams | null>(null);
  const [paginationCount, setPaginationCount] = useState(0);
  const [currentDate, setDate] = useState(new Date());
  const [renderBlocks, setRenderBlocks] = useState<ApiBlock[] | null>(null);
  const newBlock = useNewBlock();
  const isMobile = useResponsive('down', 'md');

  const {
    fetchData,
    isLoading,
    txStatus,
    data: blocks,
  } = useFetch<ApiBlocksResp>('failed to get blocks information: ');

  const queryParams = useMemo(() => {
    const queryBlockNumber = query.get('block_number');
    const queryItemsCount = query.get('items_count');
    const block_number = queryBlockNumber ? parseInt(queryBlockNumber) : 0;
    const items_count = queryItemsCount ? parseInt(queryItemsCount) : 0;
    return { block_number, items_count };
  }, [query]);

  useEffect(() => {
    if (!blocks) return;

    setRenderBlocks(blocks.items);
  }, [blocks]);

  useEffect(() => {
    if (!newBlock || page !== 1) return;

    setRenderBlocks((oldBlocks) => {
      if (!oldBlocks) return null;
      const slicedOldBlocks = oldBlocks.slice(0, -1);
      return [newBlock, ...slicedOldBlocks];
    });
  }, [newBlock]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!firstPage) return;

    setPaginationCount(
      Math.round((firstPage.block_number + firstPage.items_count) / firstPage.items_count),
    );
  }, [firstPage]);

  useEffect(() => {
    fetchFirstPageBlocks();
  }, []);

  useEffect(() => {
    fetchSelectedBlocks();
  }, [queryParams]);

  useEffect(() => {
    if (!firstPage) return;

    const { block_number, items_count } = queryParams;

    if (!block_number || !items_count) return;

    setPage(Math.round((firstPage.block_number + 2 * items_count - block_number) / items_count));
  }, [queryParams, firstPage]);

  async function fetchFirstPageBlocks() {
    const resp = await fetchBlocks(`?type=block`);

    if (!resp) return;

    setFirstPage(resp.next_page_params);
  }

  async function fetchSelectedBlocks() {
    const block_number = query.get('block_number');
    const items_count = query.get('items_count');

    const queryString = `?type=block${block_number ? `&block_number=${block_number}` : ''}${
      items_count ? `&items_count=${items_count}` : ''
    }`;

    fetchBlocks(queryString);
  }

  async function fetchBlocks(queryString: string) {
    return fetchData(api.multidata.getBlocks(queryString));
  }

  function handlePageChange(page: number) {
    if (!firstPage) return;

    const items_count = 50;

    const block_number = firstPage.block_number - (page - 2) * items_count;

    setQuery({
      items_count: String(items_count),
      block_number: String(block_number),
    });
  }

  if (isLoading) return <p>Loading...</p>;

  if (txStatus.error) return <p>{txStatus.error}</p>;

  return (
    <>
      <PageTitle title="Blocks" path="/" tooltip="Back to main page" mb={2} />
      <Stack direction="row" alignItems="flex-end" mb={3}>
        <Pagination
          siblingCount={isMobile ? 0 : 1}
          page={page}
          count={paginationCount}
          variant="soft"
          shape="rounded"
          onChange={(e, page) => handlePageChange(page)}
          style={{ marginLeft: 'auto' }}
        />
      </Stack>
      <TableContainer
        sx={{
          '& th:first-of-type, td:first-of-type': {
            paddingLeft: 3,
          },
        }}
      >
        <Table stickyHeader sx={{ overflowX: 'scroll' }}>
          <TableHeadCustom
            headLabel={[
              { id: 'block', label: 'Block', sx: { width: '8rem' } },
              {
                id: 'sizeBytes',
                label: 'Size, bytes',
                sx: { whiteSpace: 'nowrap', width: '8rem' },
              },
              { id: 'validator', label: 'Validator', sx: { width: '28rem' } },
              { id: 'txn', label: 'Txn', sx: { width: '6rem' } },
              { id: 'gasUsed', label: 'Gas used', sx: { width: '8rem' } },
              { id: 'gasUsedPercent', label: 'Gas used %' },
            ]}
          />

          <TableBody>
            {renderBlocks?.map((block) => (
              <Fade key={block.height} component={TableRow}>
                <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>
                  <Box>
                    <UiLink to={`/${CONFIG.routes.blockPage}/${block.height.toString()}`}>
                      <Typography variant="subtitle1">{block.height}</Typography>
                    </UiLink>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {timeSince(Number(new Date(block.timestamp)), Number(currentDate))}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <Typography>{block.size}</Typography>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <UiHash hash={block.miner.hash} cutting={isMobile} withCopy />
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <Typography>{block.tx_count}</Typography>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <Typography>{numberWithCommas(Number(block.gas_used))}</Typography>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <UiStatsRange value={block.gas_used_percentage} tooltip="Gas Used %" />
                </TableCell>
              </Fade>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
