import { LinkProps, Stack, StackProps, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useSnackbar } from 'notistack';
import React, { FC, memo } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { CONFIG } from 'src/app.config';
import { CopyIcon } from 'src/components/CopyIcon';
import { Iconify } from 'src/components/Iconify';
import { IconContract, IconValidator } from 'src/components/Icons';
import { UiLink } from 'src/components/ui/UiLink';
import { CHAINS } from 'src/constants/chains';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { shortenHash } from 'src/utils/ui';

interface Props extends StackProps {
  networkId?: string;
  hash: string | number;
  isContract?: boolean;
  variant?: Variant;
  cutting?: boolean;
  cuttingOptions?: [number, number];
  name?: string | null;
  withCopy?: boolean;
  isValidator?: boolean;
  isValidated?: boolean;
}

export const UiHash: FC<Props & LinkProps> = memo(
  ({
    networkId,
    hash,
    name,
    variant,
    isContract = false,
    withCopy = false,
    cutting = false,
    cuttingOptions = [],
    isValidator = false,
    isValidated = false,
    target,
    rel,
    ...others
  }) => {
    const networkExplorer = CHAINS.find((chain) => chain.id === networkId)?.explorer;
    const { address, hash: hashParam } = useParams();
    const { copy } = useCopyToClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const isTokenInstancePath = !!useMatch(
      `/${CONFIG.routes.tokenPage}/:address/instance/:tokenId`,
    );
    const isTokenPath = !!useMatch(`/${CONFIG.routes.tokenPage}/:address`) || isTokenInstancePath;

    const url =
      String(hash).length === 42
        ? CONFIG.routes.addressPage
        : String(hash).length === 66
        ? CONFIG.routes.txPage
        : CONFIG.routes.blockPage;

    function handleCopy(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      copy(String(hash));
      enqueueSnackbar('Copied!');
    }

    const LinkText = () =>
      name ? (
        <>
          <Typography component="span" variant={variant || 'body2'}>
            {name} ({shortenHash(String(hash), cuttingOptions[0], cuttingOptions[1])})
          </Typography>
        </>
      ) : (
        <Typography component="span" variant={variant || 'body2'}>
          {cutting ? shortenHash(String(hash), cuttingOptions[0], cuttingOptions[1]) : hash}
        </Typography>
      );

    const isEqualHash = address === hash || hashParam === hash;

    return (
      <Stack direction="row" alignItems="center" {...others}>
        {isValidator && <IconValidator />}
        {isContract && <IconContract />}
        {!isTokenPath && isEqualHash ? (
          <LinkText />
        ) : (
          <UiLink to={`${networkExplorer || ''}/${url}/${hash}`} target={target} rel={rel}>
            <LinkText />
          </UiLink>
        )}
        {isValidated && (
          <Iconify
            pl={0.5}
            icon="clarity:success-standard-solid"
            color={(theme) => theme.palette.success.main}
          />
        )}
        {withCopy && <CopyIcon onCopy={handleCopy} />}
      </Stack>
    );
  },
);
