import { Box, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { Iconify } from 'src/components/Iconify';
import { CONFIG } from 'src/app.config';
import { AddressContract } from 'src/components/pageAddress/AddressContract';
import { AddressIntTxs } from 'src/components/pageAddress/AddressIntTxs';
import { AddressLogs } from 'src/components/pageAddress/AddressLogs';
import { AddressTxs } from 'src/components/pageAddress/AddressTxs';
import { PageTitle } from 'src/components/PageTitle';
import { UiDivider } from 'src/components/ui/UiDivider';
import { UiGridContainer, UiGridItem } from 'src/components/ui/UiGrid';
import { UiLink } from 'src/components/ui/UiLink';
import { UiHash } from 'src/components/UiAddress';
import { CHAINS } from 'src/constants/chains';
import { useFetch } from 'src/hooks/useFetch';
import useResponsive from 'src/hooks/useResponsive';
import {
  ApiAddressCounters,
  ApiAddressInfoResp,
  ApiAddressTokensResp,
  ApiContractInfo,
  ApiTokenTransfersResp,
} from 'src/types/MultidataApi';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';
import UiDropDown from 'src/components/ui/UiDropDown';
import { TokensList } from 'src/components/TokensList';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { ChainName } from 'src/types/Asset';
import { TokensTransfers } from 'src/components/TokensTransfers';

import { UiTabs } from 'src/components/ui/UiTabs';
import { Inventory } from 'src/components/tokenDetails/Inventory';
const spaceBetweenCell = { columnGap: '0.5rem' };

const getChainName = (networkId: ChainName) => {
  return CHAINS.find((chain) => chain.id === networkId)?.name || '';
};

export const Address = () => {
  const { address } = useParams();
  const isMobile = useResponsive('down', 'sm');
  const { participants } = useContext(AssetsCtx);

  const [tab, setTab] = useState('');

  const {
    fetchData: fetchInfo,
    data: addressInfo,
    isLoading: addressIsLoading,
  } = useFetch<ApiAddressInfoResp>('failed to get address information: ');

  const {
    fetchData: fetchContractInfo,
    data: contractInfo,
    isLoading: contractIsLoading,
  } = useFetch<ApiContractInfo>('failed to get address counters: ');

  const { fetchData: fetchCounters, data: addressCounters } = useFetch<ApiAddressCounters>(
    'failed to get address counters: ',
  );

  const { fetchData: fetchBalanceTokens, data: balanceTokens } = useFetch<ApiAddressTokensResp>(
    'failed to get token balance: ',
  );

  const { fetchData: fetchErc20TokensTransfers, data: transfersErc20Tokens } =
    useFetch<ApiTokenTransfersResp>('failed to get erc-20 tokens transfers: ');

  const { fetchData: fetchNftTokensTransfers, data: transfersNftTokens } =
    useFetch<ApiTokenTransfersResp>('failed to get nft tokens transfers: ');

  const erc20TransfersTabName = 'token_transfers';
  const nftTransfersTabName = 'nft_token_transfers';

  const TABS = useMemo(() => {
    const isNft =
      addressInfo?.token &&
      (addressInfo.token?.type === 'ERC-721' || addressInfo.token?.type === 'ERC-1155');

    return [
      {
        value: 'inventory',
        label: 'Inventory',
        component: <Inventory />,
        disabled: !isNft,
      },
      {
        value: 'txs',
        label: 'Transactions',
        component: <AddressTxs />,
      },
      {
        value: 'int_txs',
        label: 'Internal transactions',
        component: <AddressIntTxs />,
      },
      {
        value: erc20TransfersTabName,
        label: 'Token Transfers (ERC-20)',
        component:
          address && transfersErc20Tokens && transfersErc20Tokens.items.length > 0 ? (
            <TokensTransfers
              fetchTransfers={(query?: string) =>
                api.multidata.getAddressTransfers(address, `?type=ERC-20${query}`)
              }
            />
          ) : null,
        disabled: !(transfersErc20Tokens && transfersErc20Tokens.items.length > 0),
      },
      {
        value: nftTransfersTabName,
        label: 'NFT Transfers',
        component:
          address && transfersNftTokens && transfersNftTokens.items.length > 0 ? (
            <TokensTransfers
              fetchTransfers={(query?: string) =>
                api.multidata.getAddressTransfers(address, `?type=ERC-721,ERC-1155${query}`)
              }
              isNft
            />
          ) : null,
        disabled: !(transfersNftTokens && transfersNftTokens.items.length > 0),
      },
      {
        value: 'contract',
        label: (
          <>
            Contract
            {addressInfo?.is_verified && (
              <Iconify
                pl={0.5}
                icon="clarity:success-standard-solid"
                color={(theme) => theme.palette.success.main}
              />
            )}
          </>
        ),
        component: (
          <AddressContract
            addressInfo={addressInfo}
            contractInfo={contractInfo}
            isLoading={addressIsLoading || contractIsLoading}
          />
        ),
        disabled: !addressInfo?.is_contract,
      },
      {
        value: 'logs',
        label: 'Logs',
        component: <AddressLogs />,
        disabled: !addressInfo?.is_contract,
      },
    ];
  }, [
    address,
    addressInfo,
    addressIsLoading,
    contractInfo,
    contractIsLoading,
    transfersErc20Tokens,
    transfersNftTokens,
  ]);

  useEffect(() => {
    setTab('');
    if (!address) return;
    fetchInfo(api.multidata.getAddressInfo(address));
    fetchCounters(api.multidata.getAddressCounters(address));
    fetchBalanceTokens(api.multidata.getAddressTokens(address));
    fetchContractInfo(api.multidata.getContractInfo(address));
    fetchErc20TokensTransfers(api.multidata.getAddressTransfers(address, '?type=ERC-20'));
    fetchNftTokensTransfers(api.multidata.getAddressTransfers(address, '?type=ERC-721,ERC-1155'));
  }, [address]);

  if (!addressInfo) return null;

  return (
    <Box>
      <PageTitle title={addressInfo.is_contract ? 'Contract details' : 'Address details'} mb={2} />
      <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
        {address && (
          <>
            <Typography variant={'subtitle1'}>
              <UiHash
                variant="subtitle1"
                hash={address}
                cutting={isMobile}
                cuttingOptions={[21, 4]}
                isContract={addressInfo.is_contract}
                withCopy
                isValidator={!!participants[address]}
                isValidated={!!participants[address]}
              />
            </Typography>
          </>
        )}
      </Stack>
      <UiGridContainer>
        {address && participants[address] && (
          <>
            <UiGridItem>
              <Typography>Validator name</Typography>
            </UiGridItem>
            <UiGridItem sx={spaceBetweenCell}>
              <Typography>
                <b>{participants[address].name}</b>
              </Typography>
            </UiGridItem>

            <UiGridItem>
              <Typography>Chain name</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography>{getChainName(participants[address].chain_name)}</Typography>
            </UiGridItem>
          </>
        )}

        <UiGridItem sx={spaceBetweenCell}>
          <Typography>Contract name</Typography>
        </UiGridItem>
        <UiGridItem>
          <Typography>{addressInfo.name || '-'}</Typography>
        </UiGridItem>
        {addressInfo.token && (
          <>
            <UiGridItem sx={spaceBetweenCell}>
              <Typography>Token name:</Typography>
            </UiGridItem>
            <UiGridItem>
              <UiLink to={`/${CONFIG.routes.tokenPage}/${address}`}>
                <Typography>
                  {addressInfo.token.name} ({addressInfo.token.symbol})
                </Typography>
              </UiLink>
            </UiGridItem>
          </>
        )}
        {addressInfo.is_contract && (
          <>
            <UiGridItem sx={spaceBetweenCell}>
              <Typography>Creator</Typography>
            </UiGridItem>
            <UiGridItem sx={spaceBetweenCell}>
              <UiHash hash={addressInfo.creator_address_hash} cuttingOptions={[4, 4]} cutting />
              at tx
              <UiHash hash={addressInfo.creation_tx_hash} cuttingOptions={[4, 4]} cutting />
            </UiGridItem>
          </>
        )}
        <UiGridItem sx={spaceBetweenCell}>
          <Typography>Balance</Typography>
        </UiGridItem>
        <UiGridItem>
          <Typography>
            {numberWithDividers(
              formatNumber.getDisplay(
                addressInfo.coin_balance,
                parseInt(addressInfo?.token?.decimals || '18'),
                4,
                false,
              ),
            )}{' '}
            CHUNK
          </Typography>
        </UiGridItem>
        {balanceTokens && balanceTokens.items.length > 0 && (
          <>
            <UiGridItem sx={spaceBetweenCell}>
              <Typography>Tokens</Typography>
            </UiGridItem>
            <UiGridItem>
              <UiDropDown
                label={
                  <>
                    <Iconify icon="radix-icons:tokens" sx={{ marginRight: '0.2rem' }} /> total:{' '}
                    {balanceTokens.items.length}
                  </>
                }
                body={<TokensList tokens={balanceTokens.items} />}
              />
            </UiGridItem>
          </>
        )}
        {addressCounters && (
          <>
            <UiGridItem sx={spaceBetweenCell}>
              <Typography>Transactions</Typography>
              <UiGridItem display={{ xs: 'flex', md: 'none' }}>
                <Typography>{numberWithDividers(addressCounters.transactions_count)}</Typography>
              </UiGridItem>
            </UiGridItem>
            <UiGridItem display={{ xs: 'none', md: 'flex' }}>
              <Typography>{numberWithDividers(addressCounters.transactions_count)}</Typography>
            </UiGridItem>
            <UiGridItem sx={spaceBetweenCell}>
              <Typography>Gas used</Typography>
              <UiGridItem display={{ xs: 'flex', md: 'none' }}>
                <Typography>{numberWithDividers(addressCounters.gas_usage_count)}</Typography>
              </UiGridItem>
            </UiGridItem>
            <UiGridItem display={{ xs: 'none', md: 'flex' }}>
              <Typography>{numberWithDividers(addressCounters.gas_usage_count)}</Typography>
            </UiGridItem>
          </>
        )}
      </UiGridContainer>
      <UiDivider />
      <UiTabs tab={tab} setTab={setTab} tabs={TABS} sx={{ mb: 6 }} />
      {TABS.map((el) => el.value === tab && <Box key={el.value}> {el.component} </Box>)}
    </Box>
  );
};
