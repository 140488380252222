import { ReactComponent as BigoneIcon } from 'src/assets/sources-small/bigone.svg';
import { ReactComponent as BinanceIcon } from 'src/assets/sources-small/binance.svg';
import { ReactComponent as BitfinexIcon } from 'src/assets/sources-small/bitfinex.svg';
import { ReactComponent as BitgetIcon } from 'src/assets/sources-small/bitget.svg';
import { ReactComponent as BitmartIcon } from 'src/assets/sources-small/bitmart.svg';
import { ReactComponent as BoosterIcon } from 'src/assets/sources-small/booster.svg';
import { ReactComponent as BybitIcon } from 'src/assets/sources-small/bybit.svg';
import { ReactComponent as CoinbaseIcon } from 'src/assets/sources-small/coinbase.svg';
import { ReactComponent as CurveIcon } from 'src/assets/sources-small/curve.svg';
import { ReactComponent as DigifinexIcon } from 'src/assets/sources-small/digifinex.svg';
import { ReactComponent as GateioIcon } from 'src/assets/sources-small/gateio.svg';
import { ReactComponent as GeminiIcon } from 'src/assets/sources-small/gemini.svg';
import { ReactComponent as HotbitIcon } from 'src/assets/sources-small/hotbit.svg';
import { ReactComponent as HuobiIcon } from 'src/assets/sources-small/huobi.svg';
import { ReactComponent as KrakenIcon } from 'src/assets/sources-small/kraken.svg';
import { ReactComponent as KucoinIcon } from 'src/assets/sources-small/kucoin.svg';
import { ReactComponent as LbankIcon } from 'src/assets/sources-small/lbank.svg';
import { ReactComponent as MexcIcon } from 'src/assets/sources-small/mexc.svg';
import { ReactComponent as OkxIcon } from 'src/assets/sources-small/okx.svg';
import { ReactComponent as P2pb2bIcon } from 'src/assets/sources-small/p2pb2b.svg';
import { ReactComponent as SushiIcon } from 'src/assets/sources-small/sushi.svg';
// import { ReactComponent as CompoundIcon } from 'src/assets/sources/compound.svg';
import { ReactComponent as UniswapIcon } from 'src/assets/sources-small/uniswap.svg';
import { ReactComponent as UpbitIcon } from 'src/assets/sources-small/upbit.svg';
import { ReactComponent as WhitebitIcon } from 'src/assets/sources-small/whitebit.svg';
import { ReactComponent as XtcomIcon } from 'src/assets/sources-small/xtcom.svg';

export const SOURCES = [
  {
    id: 'UniswapV2',
    icon: <UniswapIcon />,
  },
  {
    id: 'UniswapV3',
    icon: <UniswapIcon />,
  },
  {
    id: 'Binance',
    icon: <BinanceIcon />,
  },
  {
    id: 'Compound',
    icon: <BoosterIcon />,
  },
  {
    id: 'Huobi',
    icon: <HuobiIcon />,
  },
  {
    id: 'Kraken',
    icon: <KrakenIcon />,
  },
  {
    id: 'Curve',
    icon: <CurveIcon />,
  },
  {
    id: 'Gateio',
    icon: <GateioIcon />,
  },
  {
    id: 'Bitmart',
    icon: <BitmartIcon />,
  },
  {
    id: 'Coinbase',
    icon: <CoinbaseIcon />,
  },
  {
    id: 'Hotbit',
    icon: <HotbitIcon />,
  },
  {
    id: 'Mexc',
    icon: <MexcIcon />,
  },
  {
    id: 'Okx',
    icon: <OkxIcon />,
  },
  {
    id: 'Upbit',
    icon: <UpbitIcon />,
  },
  {
    id: 'BigOne',
    icon: <BigoneIcon />,
  },
  {
    id: 'Bitfinex',
    icon: <BitfinexIcon />,
  },
  {
    id: 'Bitget',
    icon: <BitgetIcon />,
  },
  {
    id: 'Bybit',
    icon: <BybitIcon />,
  },
  {
    id: 'Digifinex',
    icon: <DigifinexIcon />,
  },
  {
    id: 'Gemini',
    icon: <GeminiIcon />,
  },
  {
    id: 'Kucoin',
    icon: <KucoinIcon />,
  },
  {
    id: 'Lbank',
    icon: <LbankIcon />,
  },
  {
    id: 'P2pb2b',
    icon: <P2pb2bIcon />,
  },
  {
    id: 'WhiteBit',
    icon: <WhitebitIcon />,
  },
  {
    id: 'Xtcom',
    icon: <XtcomIcon />,
  },
  {
    id: 'SUSHI',
    icon: <SushiIcon />,
  },
];
