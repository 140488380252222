import { Pagination, Stack, StackProps } from '@mui/material';

type Props = {
  page: number;
  paginationCount: number;
  handlePageChange: (page: number) => void;
  disabled: boolean;
  emptyPrevPages?: boolean;
};

export const UiPagination = ({
  page,
  paginationCount,
  handlePageChange,
  disabled,
  emptyPrevPages,
  ...props
}: Props & StackProps) => {
  return (
    <>
      {(paginationCount > 1 || page !== 1) && (
        <Stack direction="row" alignItems="flex-end" width="100%" {...props}>
          <Pagination
            page={page}
            count={paginationCount}
            variant="soft"
            shape="rounded"
            onChange={(_, page) => handlePageChange(page)}
            style={{ marginLeft: 'auto' }}
            disabled={disabled}
            siblingCount={emptyPrevPages && page === paginationCount ? -1 : 0}
            boundaryCount={0}
            hidePrevButton={emptyPrevPages}
            showFirstButton
          />
        </Stack>
      )}
    </>
  );
};
