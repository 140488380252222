import { Card, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { CONFIG } from 'src/app.config';
import { Iconify } from 'src/components/Iconify';
import { UiLink } from 'src/components/ui/UiLink';
import { useTimePast } from 'src/hooks/useTimePast';
import { ApiBlock } from 'src/types/MultidataApi';

export const LatestBlocksItem: FC<{ block: ApiBlock }> = ({ block }) => {
  const timePast = useTimePast(block.timestamp);
  const theme = useTheme();

  return (
    <Card
      sx={{
        width: '100%',
        minWidth: { xs: '80vw', sm: '25rem' },
        boxShadow: 0,
        color: theme.palette.text.primary,
        bgcolor: theme.palette.background.neutral,
      }}
    >
      <UiLink to={`${CONFIG.routes.blockPage}/${block.height}`}>
        <Iconify
          icon={'eva:diagonal-arrow-right-up-fill'}
          sx={{
            p: 1.5,
            top: 24,
            right: 24,
            width: 48,
            height: 48,
            borderRadius: '50%',
            position: 'absolute',
            color: theme.palette.background.neutral,
            bgcolor: theme.palette.grey[600],
          }}
        />
      </UiLink>

      <Stack spacing={1} sx={{ p: 3 }}>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          {timePast} ago
        </Typography>

        <UiLink
          to={`${CONFIG.routes.blockPage}/${block.height}`}
          color={theme.palette.text.primary}
        >
          <Typography variant="h3">{block.height}</Typography>
        </UiLink>

        <UiLink
          to={`${CONFIG.routes.blockPage}/${block.height}?tab=transactions`}
          color={theme.palette.text.primary}
        >
          <Typography variant="subtitle2" component="span">
            {`${block.tx_count} Transactions`}
          </Typography>
        </UiLink>
      </Stack>
    </Card>
  );
};
