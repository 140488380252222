import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
import { LatestTxsItem } from 'src/components/LatestTxsItem';
import { LastItemsContainer } from 'src/components/pageHome/LastItemsContainer';
import { useFetch } from 'src/hooks/useFetch';
import { useContractChainMap } from 'src/providers/ContractChainMapProvider';
import { ApiTransaction } from 'src/types/MultidataApi';
import { NewTxsNotification } from 'src/components/NewTxsNotification';
import { useNavigate } from 'react-router-dom';

export const LastTxs = () => {
  const {
    fetchData,
    data: txs,
    isLoading,
  } = useFetch<ApiTransaction[]>('failed to get stats information: ');
  const navigate = useNavigate();
  const { getWriteChain } = useContractChainMap();

  useEffect(() => {
    fetchData(api.multidata.getMainPageTxs());
  }, []);

  if (isLoading)
    return (
      <LastItemsContainer
        title="Latest transactions"
        link={{ to: CONFIG.routes.txPage, text: 'View all transactions' }}
      >
        <Typography p={3} mb={10}>
          Loading...
        </Typography>
      </LastItemsContainer>
    );

  if (!txs) return null;

  return (
    <LastItemsContainer
      title="Latest transactions"
      UnderTitle={
        <NewTxsNotification onCounterClick={() => navigate(`${CONFIG.routes.txsPage}`)} />
      }
      link={{ to: CONFIG.routes.txsPage, text: 'View all transactions' }}
    >
      {txs.map((tx) => (
        <Grid item md={2} key={tx.hash} style={{ maxWidth: '100%' }}>
          <LatestTxsItem tx={tx} writeChain={getWriteChain(tx.to?.hash)} />
        </Grid>
      ))}
    </LastItemsContainer>
  );
};
