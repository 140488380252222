export type FiltersTypes =
  | 'All'
  | 'Token'
  | 'Stable'
  | 'Uni V2 LP'
  | 'Sushi LP'
  | 'Compound'
  | 'Yearn Vaults'
  | '3CRV';

export type Filter = {
  label: FiltersTypes;
  onClick: () => void;
  disable: boolean;
};

export const getFilters = (setFilterByType: (filterType: FiltersTypes) => void): Filter[] => [
  {
    label: 'All',
    onClick: () => {
      setFilterByType('All');
    },
    disable: true,
  },
  {
    label: 'Token',
    onClick: () => {
      setFilterByType('Token');
    },
    disable: true,
  },
  {
    label: 'Stable',
    onClick: () => {
      setFilterByType('Stable');
    },
    disable: true,
  },
  {
    label: 'Uni V2 LP',
    onClick: () => {
      setFilterByType('Uni V2 LP');
    },
    disable: true,
  },
  {
    label: 'Sushi LP',
    onClick: () => {
      setFilterByType('Sushi LP');
    },
    disable: true,
  },
  {
    label: 'Compound',
    onClick: () => {
      setFilterByType('Compound');
    },
    disable: true,
  },
  {
    label: 'Yearn Vaults',
    onClick: () => {
      setFilterByType('Yearn Vaults');
    },
    disable: true,
  },
  {
    label: '3CRV',
    onClick: () => {
      setFilterByType('3CRV');
    },
    disable: true,
  },
];
