import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { TxsTable } from 'src/components/TxsTable';

export const AddressTxs = () => {
  const address = useParams().address as string;

  const fetchTransactions = (queryString: string) =>
    api.multidata.getAddressTxs(address, queryString);

  return <TxsTable sx={{ mt: 3 }} fetchTransactions={fetchTransactions} address={address} />;
};
