import { useMemo, useState } from 'react';
import { remoteData } from 'src/utils/remoteData';
import { useRemoteData } from './useRemoteData';

export const useFetch = <T>(errorMessage?: string) => {
  const [txStatus, txStatusApi] = useRemoteData(null);
  const { setLoading, setFailure, setSuccess } = txStatusApi;
  const [data, setData] = useState<T>();

  async function fetchData(fetch: Promise<T>) {
    setLoading();
    return fetch
      .then((resp) => {
        setSuccess(null);
        setData(resp);
        return resp;
      })
      .catch((err) => {
        setFailure(
          err.body?.message || 'Something went wrong. Try refreshing the page or come back later.',
        );
        console.error(errorMessage, err);
      });
  }

  const isLoading = useMemo(() => remoteData.isLoading(txStatus), [txStatus]);

  return { fetchData, isLoading, txStatus, data };
};
