import { ADDRESS_LENGTH } from 'src/constants/staticValues';
import { VerificationMethodType } from 'src/types/MultidataApi';
import * as Yup from 'yup';

const LibrariesSchema = Yup.array().of(
  Yup.object({
    name: Yup.string().required('Required'),
    address: Yup.string()
      .required('Required')
      .length(ADDRESS_LENGTH, 'Library address (0x...) - Invalid format')
      .matches(/^0x\w+/, 'Library address (0x...) - Invalid format'),
  }),
);

const OptimizationRunsSchema = Yup.string().when('withOptimization', {
  is: true,
  then: (schema) => schema.required(),
});

const ConstructorArgumentsSchema = Yup.string().when('autodetectConstructorArgs', {
  is: false,
  then: (schema) => schema.required(),
});

export type NewContractSchema = Yup.InferType<typeof newContractSchema>;

export const newContractSchema = Yup.object().shape({
  verificationMethod: Yup.mixed<VerificationMethodType>().required(),
  details: Yup.object()
    .shape({
      compilerVersion: Yup.string().required('Required'),
      optimizationRuns: Yup.number().required('Required'),
      constructorArguments: Yup.string().required('Required'),
      isYulContract: Yup.boolean().required('Required'),
      withOptimization: Yup.boolean().required('Required'),
      contractCode: Yup.string().required('Required'),
      evmVersion: Yup.string().required('Required'),
      libraries: Yup.array()
        .required('Required')
        .of(
          Yup.object()
            .required('Required')
            .shape({
              address: Yup.string().required('Required'),
              name: Yup.string().required('Required'),
            }),
        ),
      autodetectConstructorArgs: Yup.boolean().required('Required'),
      contractName: Yup.string().required('Required'),
      files: Yup.array().required('Required').of(Yup.mixed<File>().required('Required')),
    })
    .when('verificationMethod', {
      is: 'flattened-code',
      then: () =>
        Yup.object({
          compilerVersion: Yup.string().required('Required'),
          evmVersion: Yup.string().required('Required'),
          contractCode: Yup.string().required('Required'),
          libraries: LibrariesSchema,
          optimizationRuns: OptimizationRunsSchema,
        }),
    })
    .when('verificationMethod', {
      is: 'standard-input',
      then: () =>
        Yup.object({
          contractName: Yup.string().required('Required'),
          compilerVersion: Yup.string().required('Required'),
          files: Yup.array().length(1, 'You can upload only one file'),
          constructorArguments: ConstructorArgumentsSchema,
        }),
    })
    .when('verificationMethod', {
      is: 'multi-part',
      then: () =>
        Yup.object({
          compilerVersion: Yup.string().required('Required'),
          evmVersion: Yup.string().required('Required'),
          files: Yup.array().min(1),
          libraries: LibrariesSchema,
          optimizationRuns: OptimizationRunsSchema,
        }),
    })
    .when('verificationMethod', {
      is: 'vyper-code',
      then: () =>
        Yup.object({
          contractName: Yup.string().required('Required'),
          compilerVersion: Yup.string().required('Required'),
          evmVersion: Yup.string().required('Required'),
          contractCode: Yup.string().required('Required'),
        }),
    })
    .when('verificationMethod', {
      is: 'vyper-multi-part',
      then: () =>
        Yup.object({
          compilerVersion: Yup.string().required('Required'),
          evmVersion: Yup.string().required('Required'),
          files: Yup.array().min(1),
        }),
    }),
});
